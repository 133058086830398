<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Meeskond
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'contacts.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small class="mb-0" v-if="contacts">
      <b-tr variant="primary">
        <b-th style="width: 1%;" class="text-center"> </b-th>
        <b-th>
          Nimi
        </b-th>
        <b-th style="width: 15%;" class="text-center">Aktiivne</b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>
      <draggable
        tag="tbody"
        v-model="contacts"
        group="contacts"
        @change="updateContactsOrders"
      >
        <b-tr v-for="contact in contacts" :key="contact.id">
          <b-td>
            <font-awesome-icon class="drag-element" icon="bars" />
          </b-td>
          <b-td>
            {{ contact.name }}
          </b-td>
          <b-td class="text-center">
            <font-awesome-icon
              class="text-success"
              icon="check"
              v-if="contact.is_active"
            />
            <font-awesome-icon class="text-danger" icon="times" v-else />
          </b-td>
          <b-td class="text-right">
            <router-link
              class="btn btn-warning btn-sm mr-2"
              :to="{
                name: 'contacts.edit',
                params: { contactId: contact.id },
              }"
            >
              Muuda
            </router-link>

            <b-button
              variant="danger"
              size="sm"
              @click.prevent="deleteContact(contact)"
            >
              X
            </b-button>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>

  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      isLoading: false,
      contacts: null,
    };
  },
  computed: {

  },
  mounted() {
    this.fetchContacts();
  },
  methods: {
    fetchContacts() {
      this.isLoading = true;
      RestService.getContacts().then((resposne) => {
        this.contacts = resposne.data.data;
        this.isLoading = false;
      });
    },
    updateContactsOrders() {
      this.isLoading = true;
      let ids = this.contacts.map((contact) => contact.id);

      RestService.postContactUpdateOrder({
        contacts_ids: ids,
      }).then((resposne) => {
        this.contacts = resposne.data.data;
        this.isLoading = false;
      });
    },
    deleteContact(contact) {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postContactDelete(contact.id).then(() => {
              this.fetchContacts();
            });
          }
        },
      });
    },
  },
};
</script>
