<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Lisa uus leht
      <b-button variant="success" class="float-right" @click.prevent="saveContact">
        Salvesta
      </b-button>
    </h2>

    <b-row>
      <b-col md="6">
        <b-card header="Kontaki info">

          <b-form-group
            class="mb-1"
            label="Telefon"
          >
            <b-form-input
              v-model="form.phone"
              size="sm"
              :state="getFieldStateFromErrors('phone')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("phone") }}
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group
            class="mb-3"
            label="Email"
          >
            <b-form-input
              v-model="form.email"
              type="email"
              size="sm"
              :state="getFieldStateFromErrors('email')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("email") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_active" switch>
              Kontakt on aktiivne?
            </b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card header="Kontaki pilt">
          <b-alert variant="info" show>
            Pilti saab lisada peale salvestamist.
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card>
          <template #header>
            <div>
              Tõlked
              <b-dropdown
                class="float-right"
                variant="primary"
                size="sm"
                text="Lisa tõlge"
              >
                <b-dropdown-item
                  v-for="language in languages"
                  :key="language.locale"
                  @click.prevent="addNewLanguage(language.locale)"
                  :disabled="false"
                >
                  {{ language.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <div
            v-for="translation in form.translations"
            :key="translation.id"
            class="language"
          >
            <div
              class="deletable-language"
              v-if="isLanguageDeleted(translation.locale)"
            >
              See tõlge on märgitud kustutamiseks! <br />
              <b-button
                variant="warning"
                @click.prevent="restoreLanguage(translation.locale)"
              >
                Taasta
              </b-button>
            </div>
            <h4>
              {{ getLocaleName(translation.locale) }}
              <b-button
                variant="danger"
                size="sm"
                @click.prevent="removeLanguage(translation.locale)"
              >
                Kustuta tõlge
              </b-button>
            </h4>
            <b-form-group label="Nimi" class="mb-1">
              <b-form-input
                v-model="translation.name"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Amet" class="mb-1">
              <b-form-input
                v-model="translation.position"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <hr />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import TranslationsMixin from "@/helpers/TranslationsMixin";

export default {
  mixins: [ErrorsMixin, TranslationsMixin],
  components: {

  },
  data() {
    return {
      isLoading: false,
      form: {
        deletable_languages: [],
        translations: [],
        name: null,
        phone: null,
        email: null,
        is_active: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  methods: {
    saveContact() {
      this.isLoading = true;

      let payload = this.form;

      RestService.postContactCreate(payload)
        .then((response) => {
          this.$router.push({
            name: "contacts.edit",
            params: { contactId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    addNewLanguage(locale) {
      if (this.form.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.form.translations.push({
        locale: locale,
        name: null,
        position: null,
      });
    },
  },
};
</script>
