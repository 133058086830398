var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tr',{attrs:{"variant":_vm.getRowVariant}},[_c('b-td',{staticClass:"text-center"},[_c('span',{staticClass:"checkable",on:{"click":function($event){$event.preventDefault();return _vm.handleCheck.apply(null, arguments)}}},[(_vm.product.isChecked)?_c('font-awesome-icon',{staticClass:"check-icon",attrs:{"icon":['far', 'check-square']}}):_c('font-awesome-icon',{staticClass:"check-icon inactive",attrs:{"icon":['far', 'square']}})],1)]),_c('b-td',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid",staticStyle:{"max-height":"100px"},attrs:{"src":_vm.product.main_image,"alt":""}})]),_c('b-td',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('router-link',{staticClass:"product-link",attrs:{"to":{
        name: 'products.edit',
        params: { productId: _vm.product.id },
      }}},[_c('strong',[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('br'),_c('small',[_c('strong',[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.product.id))]),_c('br'),(_vm.product.codes.length)?[_c('strong',[_vm._v("Product codes:")]),_vm._v(" "),_c('br'),_c('ul',{staticClass:"list-unstyled small"},_vm._l((_vm.product.codes),function(code){return _c('li',{key:code.id},[_c('strong',[_vm._v(" "+_vm._s(code.type)+": ")]),_vm._v(" "+_vm._s(code.code)+" ")])}),0)]:_vm._e(),_c('small',[_c('strong',[_vm._v("Mahub pakiautomaati: ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleParcelMachineCheck.apply(null, arguments)}}},[(_vm.product.is_valid_parcel)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Jah")]):(_vm.product.is_valid_parcel === false)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Ei")]):_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v("Puudub")])],1)])],2),_c('b-td',[_c('b-table-simple',[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',{staticStyle:{"width":"20%"}},[_vm._v(" Nimi ")]),_c('b-th',{staticStyle:{"width":"40%"}},[_vm._v(" Laoseis ")]),_c('b-th',{staticStyle:{"width":"40%"}},[_vm._v(" Hind ")]),_c('b-th',{staticStyle:{"width":"5%"}},[_vm._v(" Ajalugu ")])],1),_vm._l((_vm.product.stocks),function(stock){return _c('StockRow',{key:stock.id,attrs:{"stock":stock}})})],2)],1),_c('b-td',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-info btn-sm mb-1",attrs:{"href":_vm.product.product_url,"target":"_blank"}},[_vm._v(" web ")]),_c('router-link',{staticClass:"btn btn-warning btn-sm ml-1",attrs:{"to":{
        name: 'products.edit',
        params: { productId: _vm.product.id },
      }}},[_vm._v(" edit ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }