import Vue from "vue";

export const AdminRoles = Vue.component(
  "AdminRoles",
  require("./AdminRoles.vue").default
);

export const AdminRoleEdit = Vue.component(
  "AdminRoleEdit",
  require("./AdminRoleEdit.vue").default
);

export const AdminRoleNew = Vue.component(
  "AdminRoleNew",
  require("./AdminRoleNew.vue").default
);
