<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Rollid
      <router-link
        v-if="adminRoles"
        class="btn btn-primary btn-sm"
        :to="{
          name: 'adminRoles.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-alert variant="danger" v-if="showErrorMessage" show>
      Teil puudub õigus siin lehel tegutseda!
    </b-alert>

    <b-table-simple bordered responsive small class="mb-0" v-if="adminRoles">
      <b-tr variant="primary">
        <b-th style="width: 20%;">
          Nimi
        </b-th>
        <b-th>
          Õigused
        </b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>

      <b-tr v-for="adminRole in adminRoles" :key="adminRole.id">
        <b-td>
          {{ adminRole.name }}
        </b-td>
        <b-td>
          <b-badge class="mr-1" v-for="permission in adminRole.permissions" :key="permission.id">
            {{ permission.name }}
          </b-badge>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'adminRoles.edit',
              params: { adminRoleId: adminRole.id },
            }"
          >
            Muuda
          </router-link>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      adminRoles: null,
    };
  },

  mounted() {
    this.fetchAdminRoles();
  },
  methods: {
    fetchAdminRoles() {
      this.isLoading = true;
      RestService.getAdminRoles()
        .then((resposne) => {
          this.adminRoles = resposne.data.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showErrorMessage = true;
        });
    },
  },
};
</script>
