import axios from "axios";
import router from "@/router/index";

export const login = async ({ dispatch }, { payload }) => {
  await axios.get(process.env.VUE_APP_API_HOST + "/sanctum/csrf-cookie");

  await axios.post("/login", payload).then(() => {
    dispatch("fetchUser").then((response) => {
      router.replace({ name: "dashboard" });

      return response;
    });
  });
};

export const logout = ({ dispatch }) => {
  return axios.post("/logout").then(() => {
    dispatch("clearAuth");
    router.replace({ name: "login" });
  });
};

export const fetchUser = ({ commit, dispatch }) => {
  return axios.get("/me").then((response) => {
    commit("setAuthenticated", true);
    commit("setUserData", response.data.data);
    if (response.data.meta && response.data.meta.permissions) {
      commit("setUserPermissions", response.data.meta.permissions);
    }

    dispatch("generic/fetchLanguages", {}, { root: true });
  });
};

export const clearAuth = ({ commit }) => {
  commit("setAuthenticated", false);
  commit("setUserData", null);
};

export const setUserData = ({ commit }, payload) => {
  commit("setUserData", payload);
};
