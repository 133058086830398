import Vue from "vue";

export const AdminUsers = Vue.component(
  "adminUsers",
  require("./AdminUsers.vue").default
);
export const AdminUserEdit = Vue.component(
  "adminUser-edit",
  require("./AdminUserEdit.vue").default
);
export const AdminUserNew = Vue.component(
  "adminUser-new",
  require("./AdminUserNew.vue").default
);
export const AdminUserLogs = Vue.component(
  "adminUser-logs",
  require("./AdminUserLogs.vue").default
);
