import Axios from "axios";

export default class RestService {
  static checkHeartBeat() {
    return Axios.get("/heartbeat");
  }

  static getStorageItems() {
    return Axios.get("/storages/items");
  }

  static getStorageItemsGrouped() {
    return Axios.get("/storages/items/grouped");
  }

  static postStorageItem(payload) {
    return Axios.post("/storages/items", payload);
  }

  static putStorageItemStatus(id, payload) {
    return Axios.post(`/storages/items/${id}/status`, payload);
  }

  static putStorageItemInfo(id, payload) {
    return Axios.post(`/storages/items/${id}/info`, payload);
  }

  static postStorageItemProduct(id, payload) {
    return Axios.post(`/storages/items/${id}/product`, payload);
  }

  static getStorageInventories() {
    return Axios.get(`/storage/inventories`);
  }

  static getStorageInventory(id) {
    return Axios.get(`/storage/inventory/${id}`);
  }

  static postStartNewInventory() {
    return Axios.post(`/storage/inventory/start`);
  }

  static postStorageInventoryCode(inventoryId, payload) {
    return Axios.post(`/storage/inventory/${inventoryId}/code`, payload);
  }

  static postStorageInventoryAddProduct(inventoryId, payload) {
    return Axios.post(
      `/storage/inventory/${inventoryId}/increase/product`,
      payload
    );
  }

  static postStorageInventoryRemoveCode(inventoryId, payload) {
    return Axios.post(`/storage/inventory/${inventoryId}/remove/code`, payload);
  }

  static getSearchCategoryMaps(params) {
    return Axios.get("/maps/category/search", {
      params: params,
    });
  }

  static getCategoryMaps(supplierId) {
    return Axios.get("/maps/category", {
      params: {
        supplier_id: supplierId,
      },
    });
  }

  static getCategoryMapsIcecat() {
    return Axios.get(`/maps/icecat`);
  }

  static getCategoryMapIcecat(id) {
    return Axios.get(`/maps/icecat/${id}`);
  }

  static postAddCategoryToIcecatMap(mapId, categoryId) {
    return Axios.post(`/maps/icecat/${mapId}/add/category`, {
      category_id: categoryId,
    });
  }

  static postRemoveCategoryFromIcecatMap(mapId, categoryId) {
    return Axios.post(`/maps/icecat/${mapId}/remove/category`, {
      category_id: categoryId,
    });
  }

  static getIcecatCategoryProducts(mapId) {
    return Axios.get(`/maps/icecat/${mapId}/products`);
  }

  static getCategoryMap(mapId) {
    return Axios.get(`/maps/category/map/${mapId}`);
  }

  static getCategoryMapSuggestedCategories(mapId) {
    return Axios.get(`maps/category/map/${mapId}/suggested/categories`);
  }

  static getCategoryMapProducts(mapId) {
    return Axios.get(`maps/category/map/${mapId}/products`);
  }

  static addCategoryToMap(mapId, categoryId) {
    return Axios.post(`/maps/category/map/${mapId}/add/category`, {
      category_id: categoryId,
      sync: true,
    });
  }

  static postSetCategoryMapImportIgnore(mapId, trueOrfalse) {
    return Axios.post(`/maps/category/map/${mapId}/set/ignore`, {
      value: trueOrfalse,
    });
  }

  static postSetCategoryMapDeliveryPrice(mapId, price) {
    return Axios.post(`/maps/category/map/${mapId}/set/delivery-price`, {
      price: price,
    });
  }

  static postUpdateCategoryProductCounts() {
    return Axios.post(`/maps/category/update/counts`);
  }

  static removeCategoryFromMap(mapId, categoryId) {
    return Axios.post(`/maps/category/map/${mapId}/remove/category`, {
      category_id: categoryId,
    });
  }

  static getCourierStatuses() {
    return Axios.get(`/misc/courier/status`);
  }

  static postCourierStatusUpdate(id) {
    return Axios.post(`/misc/courier/status/${id}`);
  }

  static getDpdParcels() {
    return Axios.get(`/misc/shipping/methods/dpd`);
  }

  static getOmnivaParcels() {
    return Axios.get(`/misc/shipping/methods/omniva`);
  }

  static getVenipakParcels() {
    return Axios.get(`/misc/shipping/methods/venipak`);
  }

  static getSmartPostParcels() {
    return Axios.get(`/misc/shipping/methods/smartpost`);
  }

  static getSmartPostParcelsFi() {
    return Axios.get(`/misc/shipping/methods/smartpost-fi`);
  }

  static getAllShippingMethods() {
    return Axios.get(`/misc/shipping/methods`);
  }

  static getAllPaymentMethods() {
    return Axios.get(`/misc/payment/methods`);
  }

  static getOrderStatusesForList() {
    return Axios.get(`/misc/order/statuses`);
  }

  static getProductStockStatusesMisc() {
    return Axios.get(`/misc/product/stock/statuses`);
  }

  static getLanguages() {
    return Axios.get(`/misc/languages`);
  }

  static getMiscMainCategories() {
    return Axios.get(`/misc/categories/main`);
  }

  static getOrdersChartData(payload) {
    return Axios.get(`/misc/orders/chart`, {
      params: payload,
    });
  }

  static getAllShippingZones() {
    return Axios.get(`/shipping/zones`);
  }

  static getShippingZone(shippingZoneId) {
    return Axios.get(`/shipping/zones/${shippingZoneId}`);
  }

  static deleteShippingZone(zoneId) {
    return Axios.post(`/shipping/zones/${zoneId}/delete`);
  }

  static postNewShippingZone(payload) {
    return Axios.post(`/shipping/zones`, payload);
  }

  static postUpdateShippingZone(id, payload) {
    return Axios.post(`/shipping/zones/${id}/update`, payload);
  }

  static getOrders(params = {}) {
    return Axios.get("/orders", {
      params: params,
    });
  }

  static getOrdersWaitingForOffice(payload) {
    return Axios.get("/orders/products/waiting/office", {
      params: payload,
    });
  }

  static getAttentionNeededOrders() {
    return Axios.get("/orders/need/attention");
  }

  static getMyOrders(payload) {
    return Axios.get("/orders/mine", {
      params: payload,
    });
  }

  static getUnPaidOrders(payload = {}) {
    return Axios.get("/orders/unpaid", {
      params: payload,
    });
  }

  static getNotSentMeritOrders(payload = {}) {
    return Axios.get("/orders/not-sent-merit", {
      params: payload,
    });
  }

  static getOrder(orderId) {
    return Axios.get(`/orders/${orderId}`);
  }

  static saveOrderData(payload) {
    return Axios.post(`/orders/save/data`, payload);
  }

  static updateOrderStatus(payload) {
    return Axios.post(`/orders/update/status`, payload);
  }

  static postOrderSetPaid(orderId, payload) {
    return Axios.post(`/orders/${orderId}/set/paid`, payload);
  }
  
  static postOrderVatStatus(orderId, payload) {
    return Axios.post(`/orders/${orderId}/set/vat`, payload);
  }

  static postOrderSetAssignee(orderId, payload) {
    return Axios.post(`/orders/${orderId}/assignee`, payload);
  }

  static postSaveOrderDeliveryDate(orderId, payload) {
    return Axios.post(`/orders/${orderId}/update/delivery/date`, payload);
  }

  static postOrderMakeSupplierOrder(orderId, payload) {
    return Axios.post(`/orders/${orderId}/supplier/order`, payload);
  }

  static postOrderSupplierProduct(payload) {
    return Axios.post(`/orders/make/supplier/order`, payload);
  }

  static postCheckOrderSupplierStatus(orderId, supplierOrderId) {
    return Axios.post(
      `/orders/${orderId}/supplier/order/${supplierOrderId}/status`
    );
  }

  static downloadOrderPdf(orderId) {
    return Axios.get(`/orders/${orderId}/download/pdf`, {
      responseType: "blob",
    });
  }

  static downloadOrderVenipakPdf(orderId, code) {
    return Axios.get(
      `/orders/${orderId}/tracking/download/pdf/${code}/venipak`,
      {
        responseType: "blob",
      }
    );
  }

  static downloadOrderDpdPdf(orderId, code) {
    return Axios.get(`/orders/${orderId}/tracking/download/pdf/${code}/dpd`, {
      responseType: "blob",
    });
  }

  static downloadOrderSmartpostPdf(orderId, code) {
    return Axios.get(
      `/orders/${orderId}/tracking/download/pdf/${code}/smartpost`,
      {
        responseType: "blob",
      }
    );
  }

  static downloadOrderOmnivaPdf(orderId, code) {
    return Axios.get(
      `/orders/${orderId}/tracking/download/pdf/${code}/omniva`,
      {
        responseType: "blob",
      }
    );
  }

  static downloadTrackingLabelPdf(orderId, type, code) {
    return Axios.get(
      `/orders/${orderId}/tracking/download/pdf/${code}/${type}`,
      {
        responseType: "blob",
      }
    );
  }

  static postOrderGenerateDpd(orderId, payload) {
    return Axios.post(`/orders/${orderId}/tracking/generate/dpd`, payload);
  }

  static postOrderGenerateSmartpost(orderId, payload) {
    return Axios.post(
      `/orders/${orderId}/tracking/generate/smartpost`,
      payload
    );
  }

  static postOrderGenerateSmartpostFi(orderId, payload) {
    return Axios.post(
      `/orders/${orderId}/tracking/generate/smartpost-fi`,
      payload
    );
  }

  static postOrderGenerateOmniva(orderId, payload) {
    return Axios.post(`/orders/${orderId}/tracking/generate/omniva`, payload);
  }

  static postOrderGenerateVenipak(orderId, payload) {
    return Axios.post(`/orders/${orderId}/tracking/generate/venipak`, payload);
  }

  static postGenerateCourier(orderId, payload) {
    return Axios.post(`/orders/${orderId}/tracking/generate/courier`, payload);
  }

  static postOrderNotifyTracking(orderId, payload) {
    return Axios.post(`/orders/${orderId}/tracking/notify`, payload);
  }

  static sendOrderToMerit(orderId) {
    return Axios.post(`/orders/${orderId}/send/merit`);
  }

  static sendOrderToEeva(orderId) {
    return Axios.post(`/orders/${orderId}/send/eeva-order`);
  }

  static sendOrderInvoiceToEeva(orderId) {
    return Axios.post(`/orders/${orderId}/send/eeva-invoice`);
  }

  static createOrderCreditInvoice(orderId) {
    return Axios.post(`/orders/${orderId}/create/credit/invoice`);
  }

  static sendOrderInvoiceToCustomer(orderId) {
    return Axios.post(`/orders/${orderId}/send/invoice`);
  }

  static reSendOrderConfirmation(orderId) {
    return Axios.post(`/orders/${orderId}/resend/confirmation`);
  }

  static postUpdateOrderProductStatus(orderId, payload) {
    return Axios.post(`/orders/${orderId}/update/item/status`, payload);
  }

  static postUpdateOrderProductDeliveryDate(orderId, payload) {
    return Axios.post(`/orders/${orderId}/update/item/delivery`, payload);
  }

  static postUpdateOrderShippingInformation(orderId, payload) {
    return Axios.post(`/orders/${orderId}/update/shipping`, payload);
  }

  static getCreateNewOrderDefaultData(params) {
    return Axios.get(`/orders/new/default`, {
      params: params,
    });
  }

  static postCreateNewOrder(payload) {
    return Axios.post(`/orders/new`, payload);
  }

  static getOrderTypes(params = {}) {
    return Axios.get(`/orders/misc/types`, {
      params: params,
    });
  }

  static searchProducts(params, page = 1) {
    return Axios.get(`/products/search`, {
      params: {
        page: page,
        ...params,
      },
    });
  }

  static searchProductsViaCode(params) {
    return Axios.get(`/products/search/codes`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsTop(params) {
    return Axios.get(`/products/search/top`, {
      params: {
        ...params,
      },
    });
  }
  
  static getProductsFeatured(params) {
    return Axios.get(`/products/search/featured`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsMts(params) {
    return Axios.get(`/products/search/mts`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsTopOrdered(params) {
    return Axios.get(`/products/search/top-ordered`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsInActive(params) {
    return Axios.get(`/products/search/inactive`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsManualPrice(params) {
    return Axios.get(`/products/search/manual`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsDataCollectionProducts(params) {
    return Axios.get(`/products/search/data-collection`, {
      params: {
        ...params,
      },
    });
  }

  static getProductsViaStock(params) {
    return Axios.get(`/products/search/stock`, {
      params: {
        ...params,
      },
    });
  }

  static getAutoMatchedProductsFirst() {
    return Axios.get(`/products/matching/auto/first`);
  }

  static getAutoMatchedProductsForId(params) {
    return Axios.get(`/products/matching/auto/id`, {
      params: {
        ...params,
      },
    });
  }

  static postSaveProductStock(payload) {
    return Axios.post(`/products/stock/save`, payload);
  }

  static postProductCreateDuplicate(productId) {
    return Axios.post(`/product/${productId}/duplicate/product`);
  }

  static getProductCodeTypes() {
    return Axios.get(`/misc/product/code/types`);
  }

  static getCountries() {
    return Axios.get(`/misc/countries`);
  }

  static getUnits() {
    return Axios.get(`/misc/units`);
  }

  static getCategories() {
    return Axios.get(`/categories`);
  }

  static getCategoriesById(params) {
    return Axios.get(`/categories/level`, {
      params: params,
    });
  }

  static getCategory(id) {
    return Axios.get(`/category/${id}`);
  }

  static postCreateCategory(payload) {
    return Axios.post(`/category/create`, payload);
  }

  static postDeleteCategory(payload) {
    return Axios.post(`/category/delete`, payload);
  }

  static postMoveCategory(payload) {
    return Axios.post(`/category/move`, payload);
  }

  static postUpdateCategory(id, payload) {
    return Axios.post(`/category/${id}/update`, payload);
  }

  static getCategoryProducts(id, page = 1, params = {}) {
    return Axios.get(`/category/${id}/products`, {
      params: {
        page: page,
        ...params,
      },
    });
  }

  static getCategoryProductsSimple(id, page = 1, params = {}) {
    return Axios.get(`/category/${id}/products/simple`, {
      params: {
        page: page,
        ...params,
      },
    });
  }

  static getCategoryPossibleFilters(id) {
    return Axios.get(`/category/${id}/filters/possible`);
  }

  static addCategoryFilterAttribute(id, params) {
    return Axios.post(`/category/${id}/filters/add`, params);
  }

  static removeCategoryFilterAttribute(id, params) {
    return Axios.post(`/category/${id}/filters/remove`, params);
  }

  static getCategorySettings() {
    return Axios.get(`category/settings`);
  }

  static postCategoryProductsRemove(id, payload) {
    return Axios.post(`/category/${id}/products/remove`, payload);
  }

  static postCategoryProductsMove(payload) {
    return Axios.post(`category/products/move`, payload);
  }

  static postCategoryProductsAddToNew(payload) {
    return Axios.post(`category/products/add/to/new`, payload);
  }

  static getSuppliers() {
    return Axios.get(`/suppliers`);
  }

  static getSupplier(supplierId) {
    return Axios.get(`/suppliers/${supplierId}`);
  }

  static postSaveSupplier(supplierId, payload) {
    return Axios.post(`/suppliers/${supplierId}`, payload);
  }

  static postSaveNewSupplier(payload) {
    return Axios.post(`/suppliers/new`, payload);
  }

  static getSupplierDeliveryPrices(supplierId) {
    return Axios.get(`/suppliers/${supplierId}/delivery/prices`);
  }

  static postSaveSupplierPrices(supplierId, payload) {
    return Axios.post(`/suppliers/${supplierId}/prices/save`, payload);
  }

  static postSaveSupplierDeliveryPrices(supplierId, payload) {
    return Axios.post(`/suppliers/${supplierId}/delivery/prices/save`, payload);
  }

  static getImportStatus() {
    return Axios.get(`/suppliers/import/status`);
  }

  static getOfferDefaultData() {
    return Axios.get(`/offers/default/data`);
  }

  static getOffers(params = {}) {
    return Axios.get(`/offers`, {
      params: {
        ...params,
      },
    });
  }

  static getOffer(offerId) {
    return Axios.get(`/offers/${offerId}`);
  }

  static downloadOfferPdf(offerId) {
    return Axios.get(`/offers/${offerId}/download/pdf`, {
      responseType: "blob",
    });
  }

  static postSaveOffer(payload) {
    return Axios.post(`/offers/save`, payload);
  }

  static postActivateOffer(payload) {
    return Axios.post(`/offers/activate`, payload);
  }

  static postCancelOffer(payload) {
    return Axios.post(`/offers/cancel`, payload);
  }

  static postEditOffer(payload) {
    return Axios.post(`/offers/edit`, payload);
  }

  static postConfirmOffer(payload) {
    return Axios.post(`/offers/confirm`, payload);
  }

  static postConfirmOfferAndCreateOrder(payload) {
    return Axios.post(`/offers/confirm-order`, payload);
  }

  static postUpdateSupplierShipping(id, payload) {
    return Axios.post(`/supplier/shipping/${id}`, payload);
  }

  static postNewSupplierShipping(payload) {
    return Axios.post(`/supplier/shipping`, payload);
  }

  static postDeleteSupplierShipping(id) {
    return Axios.post(`/supplier/shipping/${id}/delete`);
  }

  static getProductTrackings() {
    return Axios.get(`/product/trackings`);
  }

  static deleteProductTrancking(id) {
    return Axios.post(`/product/trackings/delete/${id}`);
  }

  static postAddNewProductTracking(payload) {
    return Axios.post(`/product/trackings/new`, payload);
  }

  static getProduct(productId) {
    return Axios.get(`/products/${productId}`);
  }

  static postSaveProduct(productId, payload) {
    return Axios.post(`/products/${productId}`, payload);
  }

  static postCheckProductCodes(productId, payload) {
    return Axios.post(`/products/${productId}/check/codes`, payload);
  }

  static postAddProductCode(productId, payload) {
    return Axios.post(`/products/${productId}/add/code`, payload);
  }

  static postDeleteProductCode(productId, payload) {
    return Axios.post(`/products/${productId}/remove/code`, payload);
  }

  static postProductAddRelatedProduct(productId, payload) {
    return Axios.post(`/products/${productId}/related/add`, payload);
  }

  static postProductRemoveRelatedProduct(productId, payload) {
    return Axios.post(`/products/${productId}/related/remove`, payload);
  }

  static postDeleteProduct(id, payload) {
    return Axios.post(`/product/${id}/delete`, payload);
  }

  static postProductSetParcelMachineFit(id, payload = {}) {
    return Axios.post(`/product/${id}/set/parcel-machine-fit`, payload);
  }

  static postProductSetShowInFb(id, payload = {}) {
    return Axios.post(`/product/${id}/set/show-in-fb`, payload);
  }

  static postProductsBulkDisable(payload) {
    return Axios.post(`/products/bulk/disable`, payload);
  }

  static postProductsBulkShowFb(payload) {
    return Axios.post(`/products/bulk/show-in-fb`, payload);
  }

  static postProductsBulkParcelMachineFit(payload) {
    return Axios.post(`/products/bulk/parcel-machine-fit`, payload);
  }

  static postProductsBulkDownloadIcecat(payload) {
    return Axios.post(`/products/bulk/download/icecat`, {
      ids: payload
    });
  }

  static postProductAddNewStorageEntries(id, payload) {
    return Axios.post(`/product/${id}/add/storage/entries`, payload);
  }

  static postProductUploadImages(productId, payload) {
    return Axios.post(`/products/${productId}/upload/images`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static postCategoryUploadThumbnailImage(categoryId, payload) {
    return Axios.post(`/category/${categoryId}/upload/thumbnail`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static postCategoryUploadHomeImage(categoryId, payload) {
    return Axios.post(`/category/${categoryId}/upload/home`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static postPageUploadImage(pageId, payload) {
    return Axios.post(`/pages/${pageId}/upload/image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static postProductDeleteImage(productId, image) {
    return Axios.post(`/products/${productId}/delete/image`, {
      image: image,
    });
  }

  static postProductDeleteStock(productId, stockId) {
    return Axios.post(`/products/${productId}/delete/stock`, {
      stockId: stockId,
    });
  }

  static postProductCreateNewFile(id, payload) {
    return Axios.post(`/products/${id}/new/file`, payload);
  }

  static postProductDeleteFile(id, payload) {
    return Axios.post(`/products/${id}/delete/file`, payload);
  }

  static getUsers(payload = {}) {
    return Axios.get(`/users`, {
      params: payload,
    });
  }

  static getUser(id) {
    return Axios.get(`/users/${id}`);
  }

  static saveUser(id, payload) {
    return Axios.post(`/users/${id}`, payload);
  }

  static postSaveNewUser(payload) {
    return Axios.post(`/users/new`, payload);
  }

  static postDeleteUser(id) {
    return Axios.post(`/users/${id}/delete`);
  }

  static getPages(payload = {}) {
    return Axios.get(`/pages`, {
      params: payload,
    });
  }

  static getPage(id) {
    return Axios.get(`/pages/${id}`);
  }

  static postSavePage(id, payload) {
    return Axios.post(`/pages/${id}`, payload);
  }

  static postSaveNewPage(payload) {
    return Axios.post(`/pages/new`, payload);
  }

  static postDeletePage(id) {
    return Axios.post(`/pages/${id}/delete`);
  }

  static getStringTranslations() {
    return Axios.get(`/translations`);
  }

  static postSaveStringTranslations(payload) {
    return Axios.post(`/translations/save`, payload);
  }

  static getStatisticsSales() {
    return Axios.get(`/statistics/sales`);
  }

  static getStatisticsStorageInfo() {
    return Axios.get(`/statistics/storage`);
  }

  static getImportLogs(params = {}) {
    return Axios.get(`import/log`, { params: params });
  }

  static getOrderStatuses(params = {}) {
    return Axios.get(`/order/statuses`, { params: params });
  }

  static getOrderStatus(id) {
    return Axios.get(`/order/statuses/${id}`);
  }

  static postOrderStatus(id, payload) {
    return Axios.post(`/order/statuses/${id}`, payload);
  }

  static postDeleteOrderStatus(id) {
    return Axios.post(`/order/statuses/${id}/delete`);
  }

  static postNewOrderStatus(payload) {
    return Axios.post(`/order/statuses/new`, payload);
  }

  static getOrderStatusTypes() {
    return Axios.get(`/order/statuses/types`);
  }

  static postSaveAccountSettings(payload) {
    return Axios.post(`/auth/settings/save`, payload);
  }

  static getAccount2faData() {
    return Axios.get(`/auth/settings/2fa/data`);
  }

  static postSaveAccount2faData(payload) {
    return Axios.post(`/auth/settings/2fa/save`, payload);
  }

  static getMenus() {
    return Axios.get(`/menus`);
  }

  static getMenuLocations() {
    return Axios.get(`/menus/locations`);
  }

  static getMenu(id) {
    return Axios.get(`/menus/${id}`);
  }

  static postUpdateMenu(id, payload) {
    return Axios.post(`/menus/${id}`, payload);
  }

  static getMenuItems(id) {
    return Axios.get(`/menus/${id}/items`);
  }

  static postSaveNewMenu(payload) {
    return Axios.post(`/menus/new`, payload);
  }

  static postDeleteMenuItem(id) {
    return Axios.post(`/menus/delete/item/${id}`);
  }

  static postUpdateMenuItemsOrder(menuId, payload) {
    return Axios.post(`/menus/${menuId}/update/order`, payload);
  }

  static postAddNewMenuItem(menuId, payload) {
    return Axios.post(`/menus/${menuId}/add/item`, payload);
  }

  static getDeliveryMaps() {
    return Axios.get(`/maps/delivery`);
  }

  static postDeliveryMapNew(payload) {
    return Axios.post(`/maps/delivery/new`, payload);
  }

  static postDeliveryMapUpdate(mapId, payload) {
    return Axios.post(`/maps/delivery/${mapId}/update`, payload);
  }

  static postDeliveryMapDelete(mapId) {
    return Axios.post(`/maps/delivery/${mapId}/delete`);
  }

  static getProductStockHistory(stockId) {
    return Axios.get(`/product/stock/${stockId}/history`);
  }

  static getProductStockStatuses() {
    return Axios.get(`/product/stock/statuses`);
  }

  static getProductStockStatus(statusId) {
    return Axios.get(`/product/stock/statuses/${statusId}`);
  }

  static postSaveProductStockStatus(statusId, payload) {
    return Axios.post(`/product/stock/statuses/${statusId}`, payload);
  }

  static postSaveNewProductStockStatus(payload) {
    return Axios.post(`/product/stock/statuses/new`, payload);
  }

  static getLeases() {
    return Axios.get(`/leases`);
  }

  static getLease(id) {
    return Axios.get(`/leases/${id}`);
  }

  static postSaveNewLease(payload) {
    return Axios.post(`/leases/new`, payload);
  }

  static postSaveLease(id, payload) {
    return Axios.post(`/leases/${id}`, payload);
  }

  static postDeleteLease(id) {
    return Axios.post(`/leases/${id}/delete`);
  }

  static getAdminUsers() {
    return Axios.get(`/admin/users`);
  }

  static getAdminUser(id) {
    return Axios.get(`/admin/users/${id}`);
  }

  static getAdminUserLogs(id, params = {}) {
    return Axios.get(`/admin/users/${id}/logs`,{ params: params });
  }

  static postSaveNewAdminUser(payload) {
    return Axios.post(`/admin/users/new`, payload);
  }

  static postSaveAdminUser(id, payload) {
    return Axios.post(`/admin/users/${id}`, payload);
  }

  static postDeleteAdminUser(id) {
    return Axios.post(`/admin/users/${id}/delete`);
  }

  static getAdminRoles() {
    return Axios.get(`/admin/roles`);
  }
  
  static getAdminRole(id) {
    return Axios.get(`/admin/roles/${id}`);
  }

  static getAdminRolePermissions() {
    return Axios.get(`/admin/roles/all/permissions`);
  }

  static postAdminRoleSavePermissions(id, payload) {
    return Axios.post(`/admin/roles/${id}/permissions`, payload);
  }

  static postAdminRoleCreate(payload) {
    return Axios.post(`/admin/roles/new`, payload);
  }

  static getEmails() {
    return Axios.get(`/emails`);
  }

  static getEmail(id) {
    return Axios.get(`/emails/${id}`);
  }

  static saveEmail(id, payload) {
    return Axios.post(`/emails/${id}`, payload);
  }

  static saveNewEmail(payload) {
    return Axios.post(`/emails/new`, payload);
  }

  static sendOrderArrivedAtOffice(id) {
    return Axios.post(`/orders/${id}/send/arrived-office`);
  }

  static getBanners() {
    return Axios.get(`/banners`);
  }

  static getBanner(id) {
    return Axios.get(`/banners/${id}`);
  }

  static postSaveBanner(id, payload) {
    return Axios.post(`/banners/${id}`, payload);
  }

  static postSaveNewBanner(payload) {
    return Axios.post(`/banners/new`, payload);
  }

  static postDeleteBanner(id) {
    return Axios.post(`/banners/${id}/delete`);
  }

  static updateBannerOrder(payload) {
    return Axios.post(`/banners/update/order`, payload);
  }

  static getSupplierOrders(params) {
    return Axios.get(`/orders/suppliers`, {
      params: params,
    });
  }

  static getOrderRaportsMarginals(params) {
    return Axios.get(`/orders/reports/marginal`, {
      params: params,
    });
  }

  static postSetProductNotDuplicate(id) {
    return Axios.post(`/products/${id}/set/not/duplicate`);
  }

  static getProductMerges(params) {
    return Axios.get(`/products/merge/list`, {
      params: params,
    });
  }

  static getMergeProduct(id, params) {
    return Axios.get(`/products/merge/${id}`, {
      params: params,
    });
  }

  static postCreateProductMerge(payload) {
    return Axios.post(`/products/merge/create`, payload);
  }

  static postCreateProductMergeAuto(payload) {
    return Axios.post(`/products/merge/create/auto`, payload);
  }

  static postMergeProductConfirm(id, payload) {
    return Axios.post(`/products/merge/${id}/confirm`, payload);
  }

  static postProductMergeDelete(id) {
    return Axios.post(`/products/merge/${id}/delete`);
  }

  static postProductMergeRemoveFromMerge(id, payload) {
    return Axios.post(`/products/merge/${id}/remove/product`, payload);
  }

  static postNewProductCheckCodes(payload) {
    return Axios.post(`/products/new/check`, payload);
  }

  static postCreateNewProduct(payload) {
    return Axios.post(`/products/new/create`, payload);
  }

  static postProductDownloadIcecatData(payload) {
    return Axios.post(`/product/icecat/download`, payload);
  }

  static getProductIcecatPossibleResult(productId) {
    return Axios.get(`/product/${productId}/icecat/possible`);
  }

  static getDiscountCodes() {
    return Axios.get(`/discount/codes`);
  }

  static getDiscountCode(id) {
    return Axios.get(`/discount/codes/${id}`);
  }

  static postNewDiscountCode(payload) {
    return Axios.post(`/discount/codes/new`, payload);
  }

  static postSaveDiscountCode(id, payload) {
    return Axios.post(`/discount/codes/${id}`, payload);
  }

  static getBrandMappings(payload) {
    return Axios.get(`/brands/mappings`, {
      params: payload,
    });
  }

  static getBrandManufacturers(payload) {
    return Axios.get(`/brands/manufacturers`, {
      params: payload,
    });
  }

  static getBrandManufacturer(payload, id) {
    return Axios.get(`/brands/manufacturers/${id}`, {
      params: payload,
    });
  }

  static getBrandManufacturerSearch(payload) {
    return Axios.get(`/brands/manufacturers/search`, {
      params: payload,
    });
  }

  static getBrandManufacturersList(payload) {
    return Axios.get(`/brands/manufacturers/list`, {
      params: payload,
    });
  }

  static postBrandManufacturerNewMap(payload, id) {
    return Axios.post(`/brands/manufacturers/${id}/add/new/map`, payload);
  }

  static postSaveBrandManufacturer(id, payload) {
    return Axios.post(`/brands/manufacturers/${id}/save`, payload);
  }

  static postIcecatInfo(payload) {
    return Axios.post(`/icecat/search/id`, payload);
  }

  static getDataCollectionProductInfo(productId, payload = {}) {
    return Axios.get(`/data/collection/product/${productId}`, {
      params: payload,
    });
  }

  static getProductsFixingList(payload) {
    return Axios.get(`/products/fixing/list`, {
      params: payload,
    });
  }

  static getRmaList() {
    return Axios.get(`/rma`);
  }

  static getRmaOrder(id, payload) {
    return Axios.get(`/rma/${id}`, {
      params: payload,
    });
  }

  static postCreateNewRma(payload) {
    return Axios.post(`/rma/create`, payload);
  }
  
  static getSearchSynonyms() {
    return Axios.get(`search/synonyms`);
  }

  static getSearchSynonymsShow(id) {
    return Axios.get(`search/synonyms/${id}`);
  }

  static postCreateSearchSynonyms(payload) {
    return Axios.post(`search/synonyms/new`, payload);
  }

  static postUpdateSearchSynonyms(id, payload) {
    return Axios.post(`search/synonyms/${id}`, payload);
  }

  static postDeleteSearchSynonyms(id) {
    return Axios.post(`search/synonyms/${id}/delete`);
  }

  static postSaveSearchSynonymsSettings() {
    return Axios.post(`search/synonyms/save/settings`);
  }


  static getContacts() {
    return Axios.get(`/contacts`);
  }

  static getContact(id) {
    return Axios.get(`/contacts/${id}`);
  }

  static postContactCreate(payload) {
    return Axios.post(`/contacts/new`, payload);
  }

  static postContactUpdate(id, payload) {
    return Axios.post(`/contacts/${id}`, payload);
  }

  static postContactDelete(id) {
    return Axios.post(`/contacts/${id}/delete`);
  }

  static postContactUpdateOrder(payload) {
    return Axios.post(`/contacts/update/order`, payload);
  }

  static postContactUploadImage(id, payload) {
    return Axios.post(`/contacts/${id}/upload/image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static getBlogPosts(payload = {}) {
    return Axios.get(`/blog/posts`, {
      params: payload,
    });
  }

  static getBlogPost(id) {
    return Axios.get(`/blog/posts/${id}`);
  }

  static postBlogPostCreate(payload) {
    return Axios.post(`/blog/posts/new`, payload);
  }

  static postBlogPostUpdate(id, payload) {
    return Axios.post(`/blog/posts/${id}/update`, payload);
  }

  static postBlogPostDelete(id) {
    return Axios.post(`/blog/posts/${id}/delete`);
  }

  static postBlogPostUploadImage(id, payload) {
    return Axios.post(`/blog/posts/${id}/upload/image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
