import auth from "./auth/routes";
import dashboard from "./dashboard/routes";
import orders from "./orders/routes";
import products from "./products/routes";
import stock from "./stock/routes";
import categories from "./categories/routes";
import categoryMapper from "./categoryMapper/routes";
import offers from "./offers/routes";
import shippingZones from "./shippingZones/routes";
import productTracking from "./productTracking/routes";
import catalog from "./catalog/routes";
import users from "./users/routes";
import pages from "./pages/routes";
import translations from "./translations/routes";
import importLogs from "./importLogs/routes";
import orderStatuses from "./orderStatuses/routes";
import accountSettings from "./accountSettings/routes";
import menus from "./menus/routes";
import deliveryMapper from "./deliveryMapper/routes";
import productStockStatuses from "./productStockStatuses/routes";
import leases from "./leases/routes";
import adminUsers from "./adminUsers/routes";
import adminRoles from "./adminRoles/routes";
import suppliers from "./suppliers/routes";
import emails from "./emails/routes";
import banners from "./banners/routes";
import supplierOrders from "./supplierOrders/routes";
import productCategories from "./productCategories/routes";
import discountCodes from "./discountCodes/routes";
import brands from "./brands/routes";
import checkout from "./checkout/routes";
import searchSynonyms from "./searchSynonyms/routes";
import contacts from "./contacts/routes";
import blog from "./blog/routes";

export default [
  ...auth,
  ...dashboard,
  ...supplierOrders,
  ...orders,
  ...products,
  ...stock,
  ...categories,
  ...categoryMapper,
  ...offers,
  ...shippingZones,
  ...productTracking,
  ...catalog,
  ...users,
  ...pages,
  ...translations,
  ...importLogs,
  ...orderStatuses,
  ...accountSettings,
  ...menus,
  ...deliveryMapper,
  ...productStockStatuses,
  ...leases,
  ...adminUsers,
  ...adminRoles,
  ...suppliers,
  ...emails,
  ...banners,
  ...productCategories,
  ...discountCodes,
  ...brands,
  ...checkout,
  ...searchSynonyms,
  ...contacts,
  ...blog,
];
