<template>
  <b-card class="mt-3" no-body>
    <loader :overlay="true" v-if="isLoading" />

    <b-card-header class="p-2">
      <div class="d-inline-block" style="vertical-align: middle;">
        Seotud tooted
      </div>
      <div class="float-right">
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          @click.prevent="openNewRelatedModal"
        >
          Lisa uus
        </b-button>
      </div>
    </b-card-header>

    <SearchAndSelectProduct
      ref="searchModal"
      @selected="addNewSelectedProduct"
      @selected-product="addNewSelectedProduct"
    />

    <div class="p-2">
      <b-table-simple sticky-header="1000px" bordered responsive small>
        <b-thead>
          <b-tr variant="primary">
            <b-th style="width: 5%;">Pilt</b-th>
            <b-th>Nimi</b-th>
            <b-th style="width: 10%;"></b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="rp in product.relatedProducts" :key="rp.id">
            <b-td class="text-center">
              <img
                v-bind:src="rp.main_image"
                style="max-width: 100px; max-height: 100px;"
                alt=""
                class="img-fluid"
              />
            </b-td>
            <b-td>
              <router-link
                class="product-link"
                :to="{
                  name: 'products.edit',
                  params: { productId: rp.id },
                }"
              >
                <strong>
                  {{ rp.name }}
                </strong>
              </router-link>
              <br />
              <strong>ID: </strong>{{ rp.id }}<br />
              <template v-if="rp.codes && rp.codes.length">
                <ul class="list-unstyled small">
                  <li v-for="code in rp.codes" :key="code.id">
                    <strong> {{ code.type }}: </strong>
                    {{ code.code }}
                  </li>
                </ul>
              </template>
            </b-td>
            <b-td class="text-right">
              <b-button
                variant="danger"
                size="sm"
                @click.prevent="removeRelatedProduct(rp.id)"
              >
                Eemalda
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import SearchAndSelectProduct from "@/components/SearchAndSelectProduct";
import RestService from "@/lib/RestService";

export default {
  props: {
    product: {
      type: Object,
    },
  },
  components: {
    SearchAndSelectProduct,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {

  },
  methods: {
    openNewRelatedModal() {
      this.$refs.searchModal.open();
    },
    removeRelatedProduct(id) {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductRemoveRelatedProduct(this.product.id, {
              product_id: id
            })
            .then(() => {
              this.$emit("reloadProduct");
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
          }
        },
      });
    },
    addNewSelectedProduct(product) {
      this.isLoading = true;
      RestService.postProductAddRelatedProduct(this.product.id, {
        product_id: product.id
      })
      .then(() => {
        this.$emit("reloadProduct");
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
