<template>
  <div 
    class="offer-item"
    :class="[
      statusClass,
    ]"
    >
    <loader :overlay="true" v-if="isLoading" />
    <b-row no-gutters>
      <b-col lg="2">
        <div class="order-number">
          <router-link
            :to="{ name: 'offer.show', params: { offerId: offer.id } }"
          >
            #{{ offer.offer_nr }} <br>
            <span style="font-size:12px; font-weight: 400;">{{ offer.name }}</span>
          </router-link>
        </div>
      </b-col>
      <b-col style="line-height: 1.1" lg="3">
          {{ offer.firstname }} {{ offer.lastname }} <br>
      </b-col>
      <b-col class="text-center" lg="2">
        <div
          style="line-height: 1.2"
          v-if="offer.offer_date"
        >
          <small> Pakkumuse kuupäev: <br /> </small>
          <strong style="font-size: 14px;">
            {{ offer.offer_date | moment("DD.MM.YYYY") }}
          </strong>
        </div>
      </b-col>
      <b-col class="text-center" lg="2">
        <b-badge class="status" :class="statusClass">
          {{ offer.status_name }}
        </b-badge>
        <div
          style="line-height: 1.2"
          v-if="offer.due_date"
        >
          <small> Pakkumuse tähtaeg: <br /> </small>
          <strong style="font-size: 14px;">
            {{ offer.due_date | moment("DD.MM.YYYY") }}
          </strong>
        </div>
      </b-col>
      <b-col class="text-center" lg="2">
        <div class="price">{{ offer.total | currency }} &euro;</div>
      </b-col>
      <b-col class="text-right" lg="1">
        <b-button variant="outline-success" size="sm">PDF</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: ["offer"],
  components: {
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    statusClass() {
      if (this.offer.status == -1) {
        return "canceled";
      }

      if (this.offer.status == 0) {
        return "pending";
      }

      if (this.offer.status == 1) {
        return "processing";
      }

      if (this.offer.status == 2) {
        return "completed";
      }

      return null;
    }
  },
  methods: {

  },
};
</script>

<style lang="scss">
.offer-item {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 5px;
  border-left: 6px solid #e3e3e3;
  margin-bottom: 15px;
  position: relative;

  &.canceled {
    border-left-color: #dc3545;
  }

  &.pending {
    border-left-color: orange;
  }

  &.processing {
    border-left-color: #007bff;
  }

  &.completed {
    border-left-color: #7a9b09;
  }

  .customer-link,
  .order-number a {
    color: #000;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .order-number {
    display: block;
    margin: 0;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .price {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;

    .euro-mark {
      font-size: 18px;
    }
  }
}
</style>
