import { AdminUsers, AdminUserEdit, AdminUserNew, AdminUserLogs } from "../components";

export default [
  {
    path: "/adminUsers",
    component: AdminUsers,
    name: "adminUsers",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/adminUsers/new",
    component: AdminUserNew,
    name: "adminUsers.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/adminUsers/:adminUserId",
    component: AdminUserEdit,
    name: "adminUsers.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/adminUsers/:adminUserId/logs",
    component: AdminUserLogs,
    name: "adminUsers.logs",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
