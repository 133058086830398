import { Posts, PostEdit, PostNew } from "../components";

export default [
  {
    path: "/blog",
    component: Posts,
    name: "blog",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/blog/new",
    component: PostNew,
    name: "blog.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/blog/:postId",
    component: PostEdit,
    name: "blog.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
