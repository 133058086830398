import Vue from "vue";

export const Posts = Vue.component("contacts", require("./Posts.vue").default);
export const PostEdit = Vue.component(
  "posts-edit",
  require("./PostEdit.vue").default
);
export const PostNew = Vue.component(
  "posts-new",
  require("./PostNew.vue").default
);
