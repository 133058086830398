<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Muuda rolli
      <small v-if="adminRole">- {{ adminRole.name }}</small>
    </h2>

    <b-alert variant="danger" v-if="showErrorMessage" show>
      Teil puudub õigus siin lehel tegutseda!
    </b-alert>

    <b-row v-if="adminRole">
      <b-col md="6">
        <b-card header="Õigused">
          <b-form @submit.prevent="handleRolePermissionsSave">
            
            <b-form-group class="mb-1" v-for="p in permissions" :key="p.id">
              <b-form-checkbox v-model="activePermissions" :value="p.id" switch>
                {{ p.name }}
              </b-form-checkbox>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button
                type="submit"
                variant="success"
              >
                Salvesta
              </b-button>
            </div>

          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      adminRole: null,
      permissions: null,
      activePermissions: [],
    };
  },
  mounted() {
    this.fetchAdminRole();
  },
  methods: {
    ...mapActions({
      fetchUser: "auth/fetchUser",
    }),
    fetchAdminRole() {
      this.isLoading = true;
      RestService.getAdminRole(this.$route.params.adminRoleId).then(
        (response) => {
          this.adminRole = response.data.data;
          this.permissions = response.data.meta.permissions;

          this.activePermissions = this.adminRole.permissions.map(i => i.id);

          this.clearValidationErrors();

          this.isLoading = false;
        }
      ).catch(() => {
        this.showErrorMessage = true;
        this.isLoading = false;
      });
    },
    handleRolePermissionsSave() {
      this.isLoading = true;

      RestService.postAdminRoleSavePermissions(this.adminRole.id, {
        permissions: this.activePermissions
      }).then(
        (response) => {
          this.adminRole = response.data.data;
          this.activePermissions = this.adminRole.permissions.map(i => i.id);

          this.fetchUser();

          this.isLoading = false;
        }
      ).catch(() => {
        this.showErrorMessage = true;
        this.isLoading = false;
      });
    }
  },
};
</script>
