<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Indexi toodete otsing
    </h1>
    <b-card>
      <b-form @submit.prevent="pageChange(1, true)">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group class="mb-1">
                  <b-form-input
                    size="md"
                    v-model="searchForm.q"
                    placeholder="Otsi..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group class="pt-1">
              <b-form-checkbox
                v-model="searchForm.in_shop"
                :value="true"
                :unchecked-value="null"
                inline
              >
                Aktiivne toode
              </b-form-checkbox>
              <b-form-checkbox
                v-model="searchForm.not_in_shop"
                :value="true"
                :unchecked-value="null"
                inline
              >
                Mitteaktiivne toode
              </b-form-checkbox>
              <b-form-checkbox
                v-model="searchForm.is_featured"
                :value="true"
                :unchecked-value="null"
                inline
              >
                Avalehel
              </b-form-checkbox>
              <b-form-checkbox
                v-model="searchForm.is_used"
                :value="true"
                :unchecked-value="null"
                inline
              >
                Kasutatud toode
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="mb-3" label="Kategooria">
              <v-select
                v-if="mainCategoriesSelectLabel.length"
                v-model="searchForm.main_categories"
                :options="mainCategoriesSelectLabel"
                :reduce="(item) => item.value"
                multiple
              ></v-select>
              <b-spinner v-else small></b-spinner>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" size="md" type="submit">
              Otsi
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <div class="row mt-3" v-if="products">
      <div class="col">
        <template v-if="products.length || currentPage != 1">
          <b-button-group class="mt-2 mb-2">
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(1)"
              v-if="currentPage != 1"
              >Esimene leht</b-button
            >
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(parseInt(currentPage) - 1)"
              v-if="currentPage != 1"
              >Eelmine leht</b-button
            >
            <b-button variant="outline-primary" disabled>{{
              currentPage
            }}</b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(parseInt(currentPage) + 1)"
              >Järgmine leht</b-button
            >
          </b-button-group>
        </template>

        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 1%"></b-th>
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 50%">Name</b-th>
            <b-th>Stocks</b-th>
            <b-th style="width: 5%">Actions</b-th>
          </b-tr>
          <ProductRow
            v-for="product in products"
            :product="product"
            :key="product.id"
            @update="handleProductUpdate"
          />
        </b-table-simple>

        <b-alert v-if="!products.length && !isLoading" variant="warning" show>
          Tooteid ei leitud!
        </b-alert>

        <template v-if="products.length || currentPage != 1">
          <b-button-group class="mt-2 mb-2">
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(1)"
              v-if="currentPage != 1"
            >
              Esimene leht
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(parseInt(currentPage) - 1)"
              v-if="currentPage != 1"
            >
              Eelmine leht
            </b-button>
            <b-button variant="outline-primary" disabled>{{
              currentPage
            }}</b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="pageChange(parseInt(currentPage) + 1)"
            >
              Järgmine leht
            </b-button>
          </b-button-group>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductRow from "./productList/ProductRow";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProductRow,
  },
  data() {
    return {
      isLoading: false,
      searchForm: {
        q: null,
        in_shop: null,
        not_in_shop: null,
        is_featured: null,
        is_edited: null,
        is_price_edited: null,
        is_used: null,
        has_mts_stock: null,
        main_categories: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      products: "products/products",
      mainCategoriesSelectLabel: "generic/mainCategoriesSelectLabel",
    }),
    currentPage() {
      return this.$route.params.page;
    },
  },

  methods: {
    ...mapActions({
      searchProducts: "products/searchProducts",
      updateProductData: "products/updateProductData",
      fetchMainCategories: "generic/fetchMainCategories",
    }),
    doSearch() {
      this.isLoading = true;
      this.searchProducts({
        formdata: this.searchForm,
        page: this.currentPage,
      }).then(() => {
        this.isLoading = false;
      });
    },
    pageChange(page, isSearchSubmit = false) {
      if (page == 1 && isSearchSubmit) {
        this.doSearch();
        return;
      }

      this.$router.push({ name: "productsPager", params: { page: page } });
    },
    handleProductUpdate(product) {
      this.updateProductData(product)
    }
  },
  watch: {
    currentPage: function(current, newPage) {
      if (current != newPage) {
        this.doSearch();
      }
    },
  },
  mounted() {
    this.fetchMainCategories();
  },
};
</script>
