<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Lisa uus sooduskood
    </h1>

    <b-row>
      <b-col md="6">
        <b-card header="Sooduskoodi info">
          <b-form @submit.prevent="saveDiscountCode">
            <b-form-group class="mb-1" label="Sooduskood">
              <b-form-input
                v-model="form.code"
                size="sm"
                :state="getFieldStateFromErrors('code')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("code") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="form.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Miinimum ostukorvi summa">
              <b-input-group size="sm">
                <template #append>
                  <b-input-group-text>
                    {{ minPriceWithVat | currency }}&nbsp;&euro;
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="form.min_cart_total"
                  size="sm"
                  :state="getFieldStateFromErrors('min_cart_total')"
                ></b-form-input>
              </b-input-group>

              <b-form-invalid-feedback>
                {{ getFieldError("min_cart_total") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Alguse aeg">
              <v-date-picker
                v-model="form.starts_at"
                mode="dateTime"
                is24hr
                :model-config="dateTimeConfig"
                :masks="dateTimeMasks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    readonly
                    :value="inputValue"
                    @click="togglePopover({ placement: 'auto-start' })"
                  />
                </template>
              </v-date-picker>

              <b-form-invalid-feedback>
                {{ getFieldError("starts_at") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-3" label="Lõpuaeg">
              <v-date-picker
                v-model="form.ends_at"
                mode="dateTime"
                is24hr
                :model-config="dateTimeConfig"
                :masks="dateTimeMasks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    readonly
                    :value="inputValue"
                    @click="togglePopover({ placement: 'auto-start' })"
                  />
                </template>
              </v-date-picker>

              <b-form-invalid-feedback>
                {{ getFieldError("ends_at") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-3">
              <b-form-checkbox v-model="form.is_active" switch>
                Aktiivne
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Soodustuse tüüp">
              <b-form-radio-group v-model="form.type">
                <b-form-radio value="1">Tasuta transport</b-form-radio>
                <b-form-radio value="2">Soodus protsent</b-form-radio>
                <b-form-radio value="3">Soodus summa</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              class="mb-1"
              label="Soodustuse väärtus"
              v-if="form.type != 1"
            >
              <b-input-group size="sm">
                <template #append>
                  <b-input-group-text v-if="form.type == 3">
                    {{ minValueResult | currency }}&nbsp;&euro;
                  </b-input-group-text>
                  <b-input-group-text v-else>
                    {{ minValueResult }}&nbsp;%
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="form.value"
                  size="sm"
                  :state="getFieldStateFromErrors('value')"
                ></b-form-input>
              </b-input-group>

              <b-form-text>
                <span v-if="form.type == 2">
                  Väärtus peab olema sisestatud vahemikus <b>0.01 - 0.99</b>.
                  (Nt: 0.2 = 20%)
                  <br />
                  Soodustus võetakse ostukorvi summalt. Transpordi maksumust see
                  ei mõjuta
                </span>
                <span v-if="form.type == 3">
                  Soodustuse summa ilma käibemaksuta
                  <br />
                  Soodustus võetakse ostukorvi summalt. Transpordi maksumust see
                  ei mõjuta
                </span>
              </b-form-text>
              <b-form-invalid-feedback>
                {{ getFieldError("value") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group v-if="form.type == 1" class="mt-1">
              <b-form-checkbox-group
                v-model="form.shipping_data"
                :options="selectShippings"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>

            <template v-if="form.type != 1">
              <b-form-group label="Sooduskoodi kehtivus">
                <b-form-radio-group
                  v-model="form.subtype"
                  @change="subtypeChange"
                >
                  <b-form-radio value="0">Kogu ostukorv</b-form-radio>
                  <b-form-radio value="1">Valitud tarnijad</b-form-radio>
                  <b-form-radio value="3">Valitud tootjad</b-form-radio>
                  <b-form-radio value="4">Valitud kategooriad</b-form-radio>
                  <b-form-radio value="2">Valitud tooted</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <template v-if="form.subtype == 1">
                <b-form-group class="mb-1" label="Tarnijad">
                  <v-select
                    v-if="flatSuppliers.length"
                    v-model="form.subtype_values"
                    :options="flatSuppliers"
                    :reduce="(item) => item.code"
                    multiple
                  ></v-select>
                  <b-spinner v-else small></b-spinner>
                </b-form-group>
              </template>
              <template v-if="form.subtype == 2">
                <b-form-group class="mb-1" label="Toote id-d">
                  <b-form-tags v-model="form.subtype_values"></b-form-tags>
                </b-form-group>
              </template>
              <template v-if="form.subtype == 3">
                <b-form-group class="mb-1" label="Tootjad">
                  <b-form-tags v-model="form.subtype_values"></b-form-tags>
                </b-form-group>
              </template>
              <template v-if="form.subtype == 4">
                <b-form-group class="mb-1" label="Kategooriad">
                  <v-select
                    v-if="flatCategories.length"
                    v-model="form.subtype_values"
                    :options="flatCategories"
                    :reduce="(item) => item.code"
                    multiple
                  ></v-select>
                  <b-spinner v-else small></b-spinner>
                </b-form-group>
              </template>
            </template>

            <div class="text-right mt-3">
              <b-button type="submit" variant="success">
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,

      form: {
        code: null,
        name: null,
        min_cart_total: 0,
        starts_at: new Date(),
        ends_at: new Date(),
        is_active: false,
        type: 1,
        value: null,
        shipping_data: [],
        subtype: 0,
        subtype_values: [],
      },
      dateTimeConfig: {
        type: "string",
        mask: "iso",
      },
      dateTimeMasks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
      shipping_methods: [],
    };
  },
  computed: {
    ...mapGetters({
      suppliers: "generic/suppliers",
      categories: "categories/categories",
    }),
    selectShippings() {
      return this.shipping_methods.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
    minPriceWithVat() {
      return this.form.min_cart_total * 1.22;
    },
    minValueResult() {
      if (this.form.type == 3) {
        return this.form.value * 1.22;
      }

      if (this.form.type == 2) {
        return this.form.value * 100;
      }

      return 0;
    },
    flatSuppliers() {
      return this.suppliers.map((item) => {
        return {
          label: item.name,
          code: item.id,
        };
      });
    },
  },
  mounted() {
    this.fetchSuppliers();
    this.fetchCategories();
    this.fetchShippingMethods();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
      fetchSuppliers: "generic/fetchSuppliers",
    }),
    subtypeChange() {
      this.form.subtype_values = [];
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
    saveDiscountCode() {
      this.isLoading = true;
      RestService.postNewDiscountCode(this.form)
        .then((response) => {
          this.$router.push({
            name: "discount-codes.edit",
            params: { discountCodeId: response.data.data.id },
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    fetchShippingMethods() {
      RestService.getAllShippingMethods().then((response) => {
        this.shipping_methods = response.data.data;
      });
    },
  },
};
</script>
