<template>
  <div class="mapper">
    <b-overlay :show="isLoading" opacity="0.3" variant="white" rounded="sm">
      <h1>Kategooria mapper</h1>
      <div class="row">
        <div class="col">
          Otsi
          <div class="form-group">
            <input type="text" class="form-control" v-model="searchKey" />
          </div>

          <b-form-group class="d-inline-block">
            <b-form-checkbox
              v-model="showNotMapped"
              name="check-button"
              switch
              @change="setShowNotMappedFilter"
            >
              Kuva mappimata
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="d-inline-block ml-3">
            <b-form-checkbox
              v-model="showWithProducts"
              name="check-button2"
              switch
              @change="setShowWithProductsFilter"
            >
              Kuva ainult kus on tooteid
            </b-form-checkbox>
          </b-form-group>

          <div class="list-group scroll-list">
            <button
              type="button"
              class="list-group-item list-group-item-action"
              :class="{
                active: map.id == (selectedMap ? selectedMap.id : null),
              }"
              v-for="map in filteredCategoryMaps"
              v-bind:key="map.id"
              @click.prevent="getMap(map)"
            >
              {{ map.supplier_category }}

              <div class="float-right">
                <span
                  class="badge badge-light badge-pill mr-2"
                  v-if="map.has_products"
                >
                  on tooteid
                </span>
                <span
                  class="badge badge-warning badge-pill mr-2"
                  v-if="map.ignore_on_import"
                >
                  ignore
                </span>
                <span
                  class="badge badge-success badge-pill mr-2"
                  v-if="map.categories && map.categories.length"
                >
                  ok
                </span>
                <span class="badge badge-primary badge-pill" v-if="map.supplier">
                  {{ map.supplier.data.name }}
                </span>
              </div>
            </button>
          </div>
        </div>
        <div class="col">
          <b-card v-if="selectedMap" no-body>
            <MapInfo ref="mapInfo" :map="selectedMap" />
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import MapInfo from "./MapInfo";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    MapInfo,
  },
  data() {
    return {
      isLoading: false,
      searchKey: "",
      showNotMapped: false,
      showWithProducts: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredCategoryMaps: "categoryMapper/filteredCategoryMaps",
      selectedMap: "categoryMapper/selectedMap",
    }),
  },
  mounted() {
    this.reset();
    this.isLoading = true;
    this.fetchCategoryMaps(this.$route.params.supplierId).then(() => {
      this.isLoading = false;
    });
    this.fetchCategories();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
      reset: "categoryMapper/reset",
      fetchCategoryMaps: "categoryMapper/fetchCategoryMaps",
      getCategoryMap: "categoryMapper/getCategoryMap",
      setSearchKey: "categoryMapper/setSearchKey",
      setShowNotMapped: "categoryMapper/setShowNotMapped",
      setShowWithProducts: "categoryMapper/setShowWithProducts",
    }),
    getMap(map) {
      this.isLoading = true;
      this.getCategoryMap(map.id).then(() => {
        this.isLoading = false;
        this.$refs.mapInfo.loadProductsInMap();
      });
    },
    setShowNotMappedFilter(val) {
      this.setShowNotMapped(val);
    },
    setShowWithProductsFilter(val) {
      this.setShowWithProducts(val);
    },
  },
  watch: {
    searchKey(newValue) {
      this.setSearchKey(newValue);
    },
  },
};
</script>

<style lang="scss">
.scroll-list {
  max-height: 70vh;
  overflow-y: auto;
}

.mapper {
  .list-group-item {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
  }
}
</style>
