<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Administraatorid
      <router-link
        v-if="adminUsers"
        class="btn btn-primary btn-sm"
        :to="{
          name: 'adminUsers.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-alert variant="danger" v-if="showErrorMessage" show>
      Teil puudub õigus siin lehel tegutseda!
    </b-alert>

    <b-table-simple bordered responsive small class="mb-0" v-if="adminUsers">
      <b-tr variant="primary">
        <b-th>
          Nimi
        </b-th>
        <b-th>
          Email
        </b-th>
        <b-th style="width: 20%;">
          Rollid
        </b-th>
        <b-th style="width: 15%;"></b-th>
      </b-tr>

      <b-tr v-for="adminUser in adminUsers" :key="adminUser.id">
        <b-td>
          {{ adminUser.name }}
        </b-td>
        <b-td>
          {{ adminUser.email }}
        </b-td>
        <b-td>
          <b-badge class="mr-1" v-for="role in adminUser.roles" :key="role.id">
            {{ role.name }}
          </b-badge>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-primary btn-sm mr-2"
            :to="{
              name: 'adminUsers.logs',
              params: { adminUserId: adminUser.id },
            }"
          >
            Audit logi
          </router-link>

          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'adminUsers.edit',
              params: { adminUserId: adminUser.id },
            }"
          >
            Muuda
          </router-link>

          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deleteAdminUser(adminUser)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      adminUsers: null,
    };
  },

  mounted() {
    this.fetchAdminUsers();
  },
  methods: {
    fetchAdminUsers() {
      this.isLoading = true;
      RestService.getAdminUsers()
        .then((resposne) => {
          this.adminUsers = resposne.data.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showErrorMessage = true;
        });
    },
    deleteAdminUser(adminUser) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postDeleteAdminUser(adminUser.id).then(() => {
              this.fetchAdminUsers();
            });
          }
        },
      });
    },
  },
};
</script>
