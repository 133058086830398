<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Muuda administraatorit
      <small v-if="adminUser">- {{ adminUser.name }}</small>
    </h2>

    <b-alert variant="danger" v-if="showErrorMessage" show>
      Teil puudub õigus siin lehel tegutseda!
    </b-alert>

    <b-row v-if=adminUser>
      <b-col md="6">
        <b-card header="Uue administraatori info">
          <b-form>
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="adminUser.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Email">
              <b-form-input
                type="email"
                v-model="adminUser.email"
                size="sm"
                :state="getFieldStateFromErrors('email')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("email") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Parool">
              <b-form-input
                type="password"
                v-model="adminUser.password"
                size="sm"
                :state="getFieldStateFromErrors('password')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("password") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-3" label="Rollid">
              <v-select
                v-if="flatRoles.length"
                v-model="adminUserRoles"
                :options="flatRoles"
                :reduce="(item) => item.id"
              ></v-select>
              <b-spinner v-else small></b-spinner>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button
                type="submit"
                variant="success"
                @click.prevent="saveAdminUser"
              >
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      adminUserRoles: [],
      adminUser: null,
      marko: [],
    };
  },
  mounted() {
    this.fetchAdminUser();
  },
  computed: {
    flatRoles() {
      let roles = [];

      if (this.roles) {
        roles = this.roles.map((i) => {
          return {
            id: i.id,
            label: i.name,
          }
        });
      }

      return roles;
    },
  },
  methods: {
    fetchAdminUser() {
      this.isLoading = true;
      RestService.getAdminUser(this.$route.params.adminUserId).then(
        (response) => {
          this.adminUser = response.data.data;
          this.roles = response.data.meta.roles;

          this.adminUserRoles = this.adminUser.roles.map(i => i.id);

          this.clearValidationErrors();

          this.isLoading = false;
        }
      ).catch(() => {
        this.isLoading = false;
        this.showErrorMessage = true;
      });
    },
    saveAdminUser() {
      this.isLoading = true;

      RestService.postSaveAdminUser(this.adminUser.id, {
        ...this.adminUser,
        ...{ selected_roles: this.adminUserRoles }
      })
        .then((response) => {
          this.adminUser = response.data.data;
          this.adminUserRoles = this.adminUser.roles.map(i => i.id);

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
