<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      <b-button variant="success" class="float-right" @click.prevent="savePage">
        Salvesta
      </b-button>
      Lehe muutmine <small v-if="page">- {{ page.title }}</small>
    </h2>

    <template v-if="page">
      <b-row>
        <b-col md="6">
          <b-card header="Lehe info">
            <b-form-group
              class="mb-2"
              description="Parameter is used only in system to generate urls for pages."
              label="Süsteemi parameeter"
            >
              <b-form-input v-model="page.sys_param" size="sm"></b-form-input>
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="Template"
            >
              <b-form-select
                v-model="page.template"
                :options="templateOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="Parent"
            >
              <b-form-select
                v-model="page.parent_id"
                :options="parentOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="page.is_active" switch>
                Leht on aktiivne?
              </b-form-checkbox>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Päise pilt">
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold">Päise pilt</h5>
              </b-col>
              <b-col md="6">
                <upload
                  :isLoading="isUploadLoading"
                  @handleUpload="handleImageUpload"
                />
              </b-col>
              <b-col md="6">
                <img
                  v-if="page.image"
                  :src="page.image"
                  class="img-fluid"
                  style="max-height: 100px;"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in page.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                This language is marked for delete <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                >
                  Restore
                </b-button>
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Delete language
                </b-button>
              </h4>
              <b-form-group label="Pealkiri" class="mb-1">
                <b-form-input
                  v-model="translation.title"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Slug" class="mb-1">
                <b-form-input
                  v-model="translation.slug"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <Editor v-model="translation.content" v-if="!isLoading" />
              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
import Editor from "@/components/Editor";
import TranslationsMixin from "@/helpers/TranslationsMixin";

export default {
  mixins: [TranslationsMixin],
  components: {
    Editor,
  },
  data() {
    return {
      isLoading: false,
      page: null,
      isUploadLoading: false,
      templateOptions: [],
      parentOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  mounted() {
    this.fetchPage();
  },
  methods: {
    savePage() {
      this.isLoading = true;

      let payload = { ...this.page, ...this.form };

      RestService.postSavePage(this.page.id, payload)
        .then((response) => {
          this.page = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    fetchPage() {
      RestService.getPage(this.$route.params.pageId).then((response) => {
        this.page = response.data.data;

        this.templateOptions = response.data.meta.template_options;
        this.parentOptions = response.data.meta.parent_options;

        this.isLoading = false;
      });
    },
    handleImageUpload(files) {
      let formData = new FormData();
      formData.append("file", files[0]);

      this.isLoading = true;
      RestService.postPageUploadImage(this.page.id, formData)
        .then(() => {
          this.fetchPage();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    addNewLanguage(locale) {
      if (this.page.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.page.translations.push({
        locale: locale,
        title: null,
        slug: null,
        content: null,
      });
    },
  },
};
</script>
