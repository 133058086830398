import localforage from 'localforage'

export const setToken = (state, token) => {
    if (!token) {
        localforage.removeItem('authtoken', token)
        return
    }

    localforage.setItem('authtoken', token)
}

export const setAuthenticated = (state, trueOrFalse) => {
    state.user.authenticated = trueOrFalse
}

export const setUserData = (state, data) => {
    state.user.data = data
}

export const setUserPermissions = (state, data) => {
    state.user.permissions = data
}