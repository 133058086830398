<template>
  <div class="position-relative">
    <OrdersHeader type="stock" />
    <loader v-if="isLoading" :overlay="true" />
    <b-form @submit.prevent="fetchOrders">
      <b-form-group class="mt-3 mb-1">
        <b-input-group>
          <b-form-input
            class="form-control"
            v-model="searchKeyword"
            size="md"
            placeholder="Otsi..."
            ref="searchkey"
            @focus.native="$event.target.select()"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" size="sm" type="submit">
              Otsi
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>

    <b-table-simple
      responsive
      bordered
      small
      class="mt-3"
      v-for="order in orders"
      :key="order.id"
    >
      <b-tr variant="primary">
        <b-th>
          <router-link
            class="text-dark"
            :to="{ name: 'order.show', params: { orderId: order.id } }"
          >
            Tellimus #{{ order.id }} - {{ order.first_name }}
            {{ order.last_name }}
          </router-link>
        </b-th>
        <b-th class="text-right" style="width: 15%;">
          {{ order.created_at | moment("DD.MM.YYYY HH:mm:ss") }}
        </b-th>
      </b-tr>
      <b-tr>
        <b-td>
          <div
            class="product-border"
            v-for="product in order.product_data"
            :key="`${order.id}-${product.id}`"
            :class="
              product.sent_out || product.arrived_in_stock
                ? 'order-product-ready'
                : ''
            "
          >
            {{ product.name }} <br />
            <b-row>
              <b-col md="5">
                <small>
                  <strong>Kogus:</strong> {{ product.quantity }}
                  <br />
                  <template v-if="product.stock_name">
                    <strong>Ladu: </strong> {{ product.supplier_name }} ({{
                      product.stock_name
                    }}) <br />
                  </template>
                  <template v-if="product.sku">
                    <strong>SKU: </strong> {{ product.sku }} <br />
                  </template>
                  <template v-if="product.ean">
                    <strong>EAN: </strong> {{ product.ean }} <br />
                  </template>
                  <template v-if="product.mpn">
                    <strong>MPN: </strong> {{ product.mpn }} <br />
                  </template>
                </small>
              </b-col>
              <b-col md="3">
                <small>
                  <strong>Kaup laos: </strong>
                  <a
                    href="#"
                    @click.prevent="
                      updateStatus(order.id, product, 'arrived_in_stock')
                    "
                  >
                    <b-badge variant="success" v-if="product.arrived_in_stock">
                      Jah
                    </b-badge>
                    <b-badge variant="danger" v-else>Ei</b-badge>
                  </a>
                  <br />
                  <strong>Kaup väljastatud: </strong>
                  <a
                    href="#"
                    @click.prevent="updateStatus(order.id, product, 'sent_out')"
                  >
                    <b-badge variant="success" v-if="product.sent_out"
                      >Jah</b-badge
                    >
                    <b-badge variant="danger" v-else>Ei</b-badge>
                  </a>
                  <br />
                </small>
              </b-col>
              <b-col md="3" class="text-center">
                <b-badge class="status" :class="statusClass(order)">
                  {{ order.primary_status_name }}
                </b-badge>
                <br />
                <strong>
                  <small
                    class="order-sub-status"
                    :style="orderStatusColor(order)"
                  >
                    {{ order.order_status_name }}
                  </small>
                </strong>
              </b-col>
              <b-col md="1" class="text-center">
                <div style="font-weight: 500;">
                  Kogus:
                </div>
                <div style="font-size: 24px; font-weight: 600;">
                  {{ product.quantity }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="order.important_message">
              <b-col>
                <b-alert variant="danger" show class="mb-0">
                  <span style="white-space: pre;">
                    {{ order.important_message }}
                  </span></b-alert
                >
              </b-col>
            </b-row>
          </div>
        </b-td>
        <b-td class="text-right">
          <b-button
            variant="success"
            size="sm"
            @click.prevent="handleOrderArrivedOffice(order.id)"
            :disabled="order.primary_status_id == 3"
          >
            Tellimus saabus lattu
          </b-button>
          <br />
          <br />
          <strong>Eeldatav tarne: </strong>
          <span
            :class="isBeforeToday(order.estimated_delivery_date)"
            v-if="order.estimated_delivery_date"
          >
            {{ order.estimated_delivery_date | moment("DD.MM.YYYY") }}
          </span>
          <span class="badge badge-danger" v-else>Puudub</span>
          <br />
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import moment from "moment";
import OrdersHeader from "./OrdersHeader";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    OrdersHeader,
  },
  data() {
    return {
      isLoading: false,
      searchKeyword: null,
      orders: [],
    };
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.$refs.searchkey.$el.focus();
    }, 50);
  },
  methods: {
    fetchOrders() {
      this.isLoading = true;
      return RestService.getOrdersWaitingForOffice({
        keyword: this.searchKeyword,
      })
        .then((response) => {
          this.orders = response.data.data;
          this.isLoading = false;
          setTimeout(() => {
            this.$refs.searchkey.$el.focus();
          }, 50);
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    handleOrderArrivedOffice(orderId) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.updateOrderStatus({
              id: orderId,
              order_status: "item-arrived-to-stock",
              comment: null,
              notify: false,
            })
              .then(() => {
                this.orders = this.orders.filter((o) => o.id != orderId);
                this.searchKeyword = null;
                setTimeout(() => {
                  this.$refs.searchkey.$el.focus();
                }, 50);
                this.isLoading = false;
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    updateStatus(orderId, product, type) {
      this.isLoading = true;
      RestService.postUpdateOrderProductStatus(orderId, {
        stock_id: product.stock_id,
        id: product.id,
        type: type,
      })
        .then((response) => {
          let orderIndex = this.orders.findIndex(
            (o) => o.id == response.data.data.id
          );

          this.$set(this.orders, orderIndex, response.data.data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    isBeforeToday(date) {
      date = moment(date);
      let now = moment();

      if (date.isBefore(now)) {
        return "badge badge-danger";
      }

      return "";
    },
    statusClass(order) {
      if (order.primary_status_id == -1) {
        return "canceled";
      }

      if (order.primary_status_id == 0) {
        return "pending";
      }

      if (order.primary_status_id == 1) {
        return "processing";
      }

      if (order.primary_status_id == 2) {
        return "ordered";
      }

      if (order.primary_status_id == 3) {
        return "packing";
      }

      if (order.primary_status_id == 4) {
        return "completed";
      }

      return null;
    },
    orderStatusColor(order) {
      let color = "#000";
      let backgroundColor = "transparent";

      if (order.order_status_text_color) {
        color = order.order_status_text_color;
      }

      if (order.order_status_bg_color) {
        backgroundColor = order.order_status_bg_color;
      }

      return {
        backgroundColor: backgroundColor,
        color: color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.product-border {
  border-bottom: 1px solid #eee;

  &.order-product-ready {
    background-color: #c3e6cb;
  }

  .order-sub-status {
    padding: 2px 5px;
    border-radius: 6px;
  }
}
</style>
