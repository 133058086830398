import { AdminRoles, AdminRoleNew, AdminRoleEdit } from "../components";

export default [
  {
    path: "/admin-roles",
    component: AdminRoles,
    name: "adminRoles",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/admin-roles/new",
    component: AdminRoleNew,
    name: "adminRoles.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/admin-roles/:adminRoleId",
    component: AdminRoleEdit,
    name: "adminRoles.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
