<template>
  <editor
    v-model="text"
    api-key="no-api-key"
    :init="{
      plugins: 'code table media image link fullscreen',
      image_title: false,
      image_dimensions: false,
      image_description: false,
      relative_urls: true,
      remove_script_host: false,
      convert_urls: true,
      toolbar: this.toolbar,
      fontsize_formats: '11px 12px 14px 16px 18px 24px 32px 36px 48px',
      menubar: this.showMenuBar,
      content_css: false,

      images_upload_handler: handleImageUpload,
    }"
  />
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/media';
import 'tinymce/plugins/image';
import 'tinymce/plugins/fullscreen';

import Editor from "@tinymce/tinymce-vue";
import Axios from "axios";

export default {
  props: {
    value: {},
    basic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  computed: {
    showMenuBar() {
      if (this.basic) {
        return false;
      }

      return true;
    },
    toolbar() {
      if (this.basic) {
        return "undo redo | bold italic";
      }

      return "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | image | fullscreen";
    }
  },
  data() {
    return {
      text: this.value,
    };
  },
  methods: {
    update() {
      this.text = this.value;
    },
    async handleImageUpload(blobInfo, success, failure, progress) {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      try {
        const response = await Axios.post(
          "helpers/image",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (progress && typeof progress === "function") {
                progress(percentCompleted);
              }
            },
          }
        );

        if (response.status === 403) {
          throw new Error("HTTP Error: " + response.status);
        }

        if (response.status < 200 || response.status >= 300) {
          throw new Error("HTTP Error: " + response.status);
        }

        const json = response.data;

        if (!json || typeof json.location !== "string") {
          throw new Error("Invalid JSON: " + JSON.stringify(json));
        } else response;
        {
          return success(response.data.location);
        }
      } catch (error) {
        if (failure && typeof failure === "function") {
          failure(error.message);
        }
      }
    }
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    },
  },
};
</script>
