var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[(_vm.isLoading)?_c('loader',{attrs:{"overlay":true}}):_vm._e(),_c('h1',[_vm._v(" Administraatorid "),(_vm.adminUsers)?_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
        name: 'adminUsers.new',
      }}},[_vm._v(" Lisa uus ")]):_vm._e()],1),(_vm.showErrorMessage)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(" Teil puudub õigus siin lehel tegutseda! ")]):_vm._e(),(_vm.adminUsers)?_c('b-table-simple',{staticClass:"mb-0",attrs:{"bordered":"","responsive":"","small":""}},[_c('b-tr',{attrs:{"variant":"primary"}},[_c('b-th',[_vm._v(" Nimi ")]),_c('b-th',[_vm._v(" Email ")]),_c('b-th',{staticStyle:{"width":"20%"}},[_vm._v(" Rollid ")]),_c('b-th',{staticStyle:{"width":"15%"}})],1),_vm._l((_vm.adminUsers),function(adminUser){return _c('b-tr',{key:adminUser.id},[_c('b-td',[_vm._v(" "+_vm._s(adminUser.name)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(adminUser.email)+" ")]),_c('b-td',_vm._l((adminUser.roles),function(role){return _c('b-badge',{key:role.id,staticClass:"mr-1"},[_vm._v(" "+_vm._s(role.name)+" ")])}),1),_c('b-td',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-primary btn-sm mr-2",attrs:{"to":{
            name: 'adminUsers.logs',
            params: { adminUserId: adminUser.id },
          }}},[_vm._v(" Audit logi ")]),_c('router-link',{staticClass:"btn btn-warning btn-sm mr-2",attrs:{"to":{
            name: 'adminUsers.edit',
            params: { adminUserId: adminUser.id },
          }}},[_vm._v(" Muuda ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.deleteAdminUser(adminUser)}}},[_vm._v(" X ")])],1)],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }