export const user = (state) => {
  return state.user;
};

export const isAuthenticated = (state) => {
  if (!state.user) {
    return false;
  }
  return state.user.authenticated;
};

export const userHasPermission = (state) => (payload) => {
  if (!state.user) {
    return false;
  }

  if (!state.user.permissions) {
    return false;
  }

  if (!state.user.permissions.includes(payload)) {
    return false;
  }

  return true;
};

export const userHasPermissionOnList = (state) => (payload) => {
  if (!state.user) {
    return false;
  }

  if (!state.user.permissions) {
    return false;
  }

  if (state.user.permissions.some(item => payload.includes(item))) {
    return true;
  }

  return false;
};
