<template>
  <div class="position-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Toodete merge
    </h1>

    <b-card header="Merge" v-if="merges.length">
      <b-row class="mb-5">
        <b-col>
          <b-form-group class="mb-3" label="Peatoote ID">
            <b-form-radio
              v-model="form.main_id"
              name="main-id"
              :value="merge.product.id"
              v-for="(merge, mergeKey) in merges"
              :key="`merge-id-${mergeKey}`"
            >
              {{ merge.product.id }}
            </b-form-radio>
          </b-form-group>
          <b-form-group class="mb-1" label="Tootja">
            <b-form-input v-model="form.manufacturer" size="sm"></b-form-input>
          </b-form-group>
          <b-form-group class="mb-1" label="Kategooria">
            <v-select
              v-if="flatCategories.length"
              v-model="form.categories"
              :options="flatCategories"
              :reduce="(item) => item.code"
              multiple
            ></v-select>
            <b-spinner v-else small></b-spinner>
          </b-form-group>

          <b-form-group class="mb-1" label="Soovituslik hind">
            <b-form-input
              v-model="form.recommended_price"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mb-1" label="Toote kaal (kg)">
            <b-form-input
              type="number"
              v-model="form.weight"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_featured" switch>
              Is featured
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_category_ready" switch>
              Is category ready
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="form.is_disabled" switch>
              Is disabled
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <strong>Koodid:</strong>
          <b-table-simple small bordered>
            <b-tr variant="primary">
              <b-th>Tüüp</b-th>
              <b-th>Kood</b-th>
              <b-th style="width: 20%;">Eemalda</b-th>
            </b-tr>
            <b-tr v-for="code in form.codes" :key="`code-${code.id}`">
              <b-td>
                {{ code.type }}
              </b-td>
              <b-td>
                <b-form-input v-model="code.code" size="sm"></b-form-input>
              </b-td>
              <b-td class="text-center">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click.prevent="removeCode(code.id)"
                >
                  <font-awesome-icon icon="times" />
                </b-button>
              </b-td>
            </b-tr>
          </b-table-simple>

          <strong>Laod:</strong>
          <b-table-simple small bordered>
            <b-tr variant="primary">
              <b-th style="width: 70%;">Nimi</b-th>
              <b-th>Hind</b-th>
            </b-tr>
            <b-tr v-for="stock in form.stocks" :key="`stock-${stock.id}`">
              <b-td>{{ stock.supplier.name }}</b-td>
              <b-td> {{ stock.price | currency }}&nbsp;&euro; </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
        <b-col>
          <strong>Pildid:</strong>
          <b-table-simple small bordered>
            <b-tr variant="primary">
              <b-th>Pilt</b-th>
              <b-th style="width: 30%;">Eemalda</b-th>
            </b-tr>
            <b-tr
              v-for="(image, imageKey) in form.images"
              :key="`image-${imageKey}`"
            >
              <b-td>
                <img
                  :src="image.url"
                  alt=""
                  class="img-fluid"
                  style="max-width: 100px;"
                />
              </b-td>
              <b-td class="text-center">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click.prevent="removeImage(imageKey)"
                >
                  <font-awesome-icon icon="times" />
                </b-button>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
      <h4>
        Tõlked
        <b-dropdown variant="primary" size="sm" text="Lisa Tõlge">
          <b-dropdown-item
            v-for="language in languages"
            :key="language.locale"
            @click.prevent="addNewLanguage(language.locale)"
            :disabled="false"
          >
            {{ language.name }}
          </b-dropdown-item>
        </b-dropdown>
      </h4>
      <hr />
      <b-row>
        <b-col class="pt-3 pb-3 merge-green">
          <div
            v-for="translation in form.translations"
            :key="`main-${translation.id}`"
            class="language"
          >
            <h4>
              {{ getLocaleName(translation.locale) }}
              <b-button
                variant="danger"
                size="sm"
                :disabled="form.translations.length <= 1"
                @click.prevent="removeTranslation(translation.id)"
              >
                Kustuta tõlge
              </b-button>
            </h4>
            <b-form-group label="Nimi" class="mb-1">
              <b-form-input v-model="translation.name" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Meta sõnad" class="mb-1">
              <b-form-tags
                v-model="translation.keywords"
                size="sm"
              ></b-form-tags>
            </b-form-group>
            <b-form-group label="Lühikirjeldus" class="mb-3">
              <b-button
                variant="outline-primary"
                size="sm"
                v-if="translation.short_description === null"
                @click.prevent="addShortDesc(translation.id)"
              >
                Lisa lühikirjeldus
              </b-button>
              <Editor
                v-model="translation.short_description"
                v-if="!isLoading && translation.short_description !== null"
                :basic="true"
              />
            </b-form-group>
            <b-form-group label="Kirjeldus" class="mb-3">
              <Editor v-model="translation.description" v-if="!isLoading" />
            </b-form-group>
            <hr />
          </div>
        </b-col>
        <b-col
          v-for="(merge, mergeKey) in mergesForTranslation"
          :key="`merge-${mergeKey}`"
        >
          <div
            v-for="translation in merge.product.translations"
            :key="`${merge.product.id}-${translation.id}`"
            class="language"
          >
            <h4>
              {{ getLocaleName(translation.locale) }}
              <b-button variant="outline-primary" size="sm" pill @click.prevent="replaceTranslation(translation)">
                <font-awesome-icon icon="plus" />
              </b-button>
            </h4>
            <b-form-group label="Nimi" class="mb-1">
              <b-form-input v-model="translation.name" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group label="Meta sõnad" class="mb-1">
              <b-form-tags
                v-model="translation.keywords"
                size="sm"
              ></b-form-tags>
            </b-form-group>
            <b-form-group label="Lühikirjeldus" class="mb-3">
              <Editor
                v-model="translation.short_description"
                v-if="!isLoading && translation.short_description !== null"
                :basic="true"
              />
            </b-form-group>
            <b-form-group label="Kirjeldus" class="mb-3">
              <Editor v-model="translation.description" v-if="!isLoading" />
            </b-form-group>
            <hr />
          </div>
        </b-col>
      </b-row>

      <div class="p-3 mt-5 text-center">
        <b-button variant="success" @click.prevent="confirmMerge"
          >Kinnita merge</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import { mapActions, mapGetters } from "vuex";
import Editor from "@/components/Editor";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      isLoading: false,
      merges: [],
      form: {
        main_id: null,
        manufacturer: null,
        categories: [],
        recommended_price: null,
        weight: null,
        is_featured: false,
        show_in_fb: false,
        is_category_ready: false,
        is_disabled: false,
        codes: [],
        images: [],
        stocks: [],
        translations: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
      categories: "categories/categories",
    }),
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
    mergesForTranslation() {
      return this.merges.filter(
        (m) => m.product.id != this.merges[0]["product"]["id"]
      );
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchData();
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
    }),
    fetchData() {
      this.isLoading = true;
      RestService.getMergeProduct(this.$route.params.productId)
        .then((response) => {
          this.merges = response.data.data;
          this.setInitialData();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    setInitialData() {
      this.merges.forEach((m) => {
        if (!this.form.main_id) {
          this.form.main_id = m.product.id;
        }

        if (!this.form.manufacturer) {
          this.form.manufacturer = m.product.manufacturer;
        }

        if (!this.form.recommended_price) {
          this.form.recommended_price = m.product.recommended_price;
        }

        if (!this.form.weight) {
          this.form.weight = m.product.weight;
        }

        if (!this.form.translations.length) {
          this.form.translations = m.product.translations;
        }

        let catList = [];
        m.product.categories.data.forEach((cat) => {
          if (
            !this.form.categories.includes(cat.id) &&
            !catList.includes(cat.id)
          ) {
            catList.push(cat.id);
          }
        });

        this.form.categories = this.form.categories.concat(catList);
        this.form.codes = this.form.codes.concat(m.product.codes);
        this.form.images = this.form.images.concat(m.product.images);
        this.form.stocks = this.form.stocks.concat(m.product.stocks);
      });
    },
    confirmMerge() {
      this.isLoading = true;
      RestService.postMergeProductConfirm(
        this.$route.params.productId,
        this.form
      )
        .then((response) => {
          this.$router.push({
            name: "products.edit",
            params: { productId: response.data.data },
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.isLoading = false;
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    replaceTranslation(translation) {
      if (!this.form.translations.filter((t) => t.locale == translation.locale).length) {
        this.form.translations.push(translation);

        return;
      }

      let translationIndex = this.form.translations.findIndex(
        (t) => t.locale == translation.locale
      );

      this.$set(this.form.translations, translationIndex, translation);
    },
    addNewLanguage(locale) {
      if (this.form.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.form.translations.push({
        locale: locale,
        name: null,
        description: null,
        short_description: "",
      });
    },
    addShortDesc(translationId) {
      let translationIndex = this.form.translations.findIndex(
        (t) => t.id == translationId
      );
      this.form.translations[translationIndex]["short_description"] = "";
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    removeCode(id) {
      this.form.codes = this.form.codes.filter((c) => c.id != id);
    },
    removeTranslation(id) {
      this.form.translations = this.form.translations.filter((t) => t.id != id);
    },
    getLocaleName(locale) {
      let language = this.languages.find((l) => l.locale == locale);

      if (!language) {
        return locale;
      }

      return language.name;
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
  },
};
</script>

<style lang="scss">
.merge-green {
  background-color: rgba(0, 175, 54, 0.1);
}
</style>
