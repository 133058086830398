<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      <b-button variant="success" class="float-right" @click.prevent="savePost">
        Salvesta
      </b-button>
      Postituse muutmine <small v-if="post">- {{ post.title }}</small>
    </h2>

    <template v-if="post">
      <b-row>
        <b-col md="6">
          <b-card header="Postituse info">
            
            <b-form-group class="mb-3" label="Avaldamise aeg">
              <v-date-picker
                v-model="post.published_at"
                mode="dateTime"
                is24hr
                :model-config="dateTimeConfig"
                :masks="dateTimeMasks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    readonly
                    :value="inputValue"
                    @click="togglePopover({ placement: 'auto-start' })"
                  />
                </template>
              </v-date-picker>

              <b-form-invalid-feedback>
                {{ getFieldError("published_at") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox v-model="post.is_active" switch>
                Postitus on aktiivne?
              </b-form-checkbox>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Kontaki pilt">
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold">Pilt</h5>
              </b-col>
              <b-col md="6">
                <upload
                  :isLoading="isUploadLoading"
                  @handleUpload="handleImageUpload"
                />
              </b-col>
              <b-col md="6">
                <img
                  v-if="post.image"
                  :src="post.image.thumbnail_url"
                  class="img-fluid"
                  style="max-height: 100px;"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in post.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                See tõlge on märgitud kustutamiseks! <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                >
                  Taasta
                </b-button>
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Kustuta tõlge
                </b-button>
              </h4>

              <b-form-group label="Pealkiri" class="mb-1">
                <b-form-input
                  v-model="translation.title"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Slug" class="mb-1">
                <b-form-input
                  v-model="translation.slug"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <Editor v-model="translation.content" v-if="!isLoading" />
              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
import TranslationsMixin from "@/helpers/TranslationsMixin";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import Editor from "@/components/Editor";

export default {
  mixins: [ErrorsMixin, TranslationsMixin],
  components: {
    Editor
  },
  data() {
    return {
      isLoading: false,
      post: null,
      isUploadLoading: false,
      templateOptions: [],
      parentOptions: [],
      dateTimeConfig: {
        type: "string",
        mask: "iso",
      },
      dateTimeMasks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  mounted() {
    this.fetchPost();
  },
  methods: {
    fetchPost() {
      RestService.getBlogPost(this.$route.params.postId).then((response) => {
        this.setPost(response.data.data);
        this.isLoading = false;
      });
    },
    savePost() {
      this.isLoading = true;

      let payload = { ...this.post, ...this.form };

      RestService.postBlogPostUpdate(this.post.id, payload)
        .then((response) => {
          this.setPost(response.data.data);

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    handleImageUpload(files) {
      let formData = new FormData();
      formData.append("file", files[0]);

      this.isLoading = true;
      RestService.postBlogPostUploadImage(this.post.id, formData)
        .then(() => {
          this.fetchPost();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setPost(data) {
      this.post = data;
      this.post.published_at = new Date(data.published_at);
    },
    addNewLanguage(locale) {
      if (this.post.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.post.translations.push({
        locale: locale,
        slug: null,
        title: null,
        content: null,
      });
    },
  },
};
</script>
