<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      <b-button variant="success" class="float-right" @click.prevent="saveContact">
        Salvesta
      </b-button>
      Kontaki muutmine <small v-if="contact">- {{ contact.name }}</small>
    </h2>

    <template v-if="contact">
      <b-row>
        <b-col md="6">
          <b-card header="Kontaki info">
            <b-form-group
            class="mb-1"
            label="Telefon"
          >
            <b-form-input
              v-model="contact.phone"
              size="sm"
              :state="getFieldStateFromErrors('phone')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("phone") }}
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group
            class="mb-3"
            label="Email"
          >
            <b-form-input
              v-model="contact.email"
              type="email"
              size="sm"
              :state="getFieldStateFromErrors('email')"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ getFieldError("email") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="mb-1">
            <b-form-checkbox v-model="contact.is_active" switch>
              Kontakt on aktiivne?
            </b-form-checkbox>
          </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Kontaki pilt">
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold">Päise pilt</h5>
              </b-col>
              <b-col md="6">
                <upload
                  :isLoading="isUploadLoading"
                  @handleUpload="handleImageUpload"
                />
              </b-col>
              <b-col md="6">
                <img
                  v-if="contact.image"
                  :src="contact.image.thumbnail_url"
                  class="img-fluid"
                  style="max-height: 100px;"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card>
            <template #header>
              <div>
                Tõlked
                <b-dropdown
                  class="float-right"
                  variant="primary"
                  size="sm"
                  text="Lisa tõlge"
                >
                  <b-dropdown-item
                    v-for="language in languages"
                    :key="language.locale"
                    @click.prevent="addNewLanguage(language.locale)"
                    :disabled="false"
                  >
                    {{ language.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <div
              v-for="translation in contact.translations"
              :key="translation.id"
              class="language"
            >
              <div
                class="deletable-language"
                v-if="isLanguageDeleted(translation.locale)"
              >
                See tõlge on märgitud kustutamiseks! <br />
                <b-button
                  variant="warning"
                  @click.prevent="restoreLanguage(translation.locale)"
                >
                  Taasta
                </b-button>
              </div>
              <h4>
                {{ getLocaleName(translation.locale) }}
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="removeLanguage(translation.locale)"
                >
                  Kustuta tõlge
                </b-button>
              </h4>
              <b-form-group label="Nimi" class="mb-1">
                <b-form-input
                  v-model="translation.name"
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Amet" class="mb-1">
                <b-form-input
                  v-model="translation.position"
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapGetters } from "vuex";
import { errorMessage } from "@/lib/messages";
import TranslationsMixin from "@/helpers/TranslationsMixin";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin, TranslationsMixin],
  components: {},
  data() {
    return {
      isLoading: false,
      contact: null,
      isUploadLoading: false,
      templateOptions: [],
      parentOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
  },
  mounted() {
    this.fetchContact();
  },
  methods: {
    saveContact() {
      this.isLoading = true;

      let payload = { ...this.contact, ...this.form };

      RestService.postContactUpdate(this.contact.id, payload)
        .then((response) => {
          this.contact = response.data.data;

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    fetchContact() {
      RestService.getContact(this.$route.params.contactId).then((response) => {
        this.contact = response.data.data;
        this.isLoading = false;
      });
    },
    handleImageUpload(files) {
      let formData = new FormData();
      formData.append("file", files[0]);

      this.isLoading = true;
      RestService.postContactUploadImage(this.contact.id, formData)
        .then(() => {
          this.fetchContact();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    addNewLanguage(locale) {
      if (this.contact.translations.filter((t) => t.locale == locale).length) {
        errorMessage("Viga", "See keel on juba lisatud");
        return;
      }

      this.contact.translations.push({
        locale: locale,
        name: null,
        position: null,
      });
    },
  },
};
</script>
