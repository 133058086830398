<template>
  <div>
    <b-card class="mt-3" no-body>
      <b-card-header class="p-2">
        <div class="d-inline-block" style="vertical-align: middle;">
          Toote failid
        </div>
        <div class="float-right">
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            @click.prevent="openFilesModal"
          >
            Lisa uus
          </b-button>
        </div>
      </b-card-header>

      <div class="p-2">
        <b-list-group v-if="product.productFiles && product.productFiles.length">
          <draggable v-model="product.productFiles" group="pFiles">
            <b-list-group-item
              v-for="file in product.productFiles"
              :key="file.id"
              class="p-2 clearfix"
            >
              {{ file.name }} <b-badge>{{ file.type }}</b-badge>
              <div class="float-right">
                <b-button
                  :href="file.url"
                  class="mr-2"
                  variant="primary"
                  size="sm"
                  target="_blank"
                >
                  Ava
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  @click.prevent="handleDeleteFile(file.id)"
                >
                  Kustuta
                </b-button>
              </div>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </div>
    </b-card>

    <b-modal
      v-model="showFilesModal"
      hide-footer
      size="md"
      title="Lisa uus fail"
    >
      <loader :overlay="true" v-if="isLoading" />

      <b-form @submit.prevent="createNewFile">

        <b-form-group class="mb-1" label="Fail">
          <b-form-file
            v-model="newForm.file"
            accept="*"
            size="sm"
            placeholder="Vali fail"
            drop-placeholder="Lohista fail siia"
            @input="fileSelected"
          ></b-form-file>
        </b-form-group>

        <b-form-group class="mb-1" label="Tüüp">
          <b-form-select
            v-model="newForm.type"
            :options="typeOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mb-1" label="Nimi">
          <b-form-input
            v-model="newForm.name"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <div class="text-right mt-3">
          <b-button type="submit" variant="success">
            Lisa uus
          </b-button>
        </div>

      </b-form>
    </b-modal>

  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import draggable from "vuedraggable";

export default {
  props: {
    product: {
      type: Object,
    },
  },
  components: {
    draggable
  },
  data() {
    return {
      isLoading: false,
      showFilesModal: false,
      newForm: {
        name: null,
        type: 'MANUAL',
        file: null,
      },
      typeOptions: [
        { value: "MANUAL", text: "Kasutusjuhend" },
        { value: "OTHER", text: "Muu" },
      ]
    };
  },
  computed: {

  },
  methods: {
    openFilesModal() {
      this.showFilesModal = true;
    },
    fileSelected() {
      if (this.newForm.file) {

        if (!this.newForm.name) {
          this.newForm.name = this.newForm.file.name
        }

      }
    },
    async createNewFile() {
      this.isLoading = true

      let file = await this.toBase64(this.newForm.file);

      RestService.postProductCreateNewFile(this.product.id, {
        ...this.newForm,
        ...{ file: file, file_name: this.newForm.file.name}
      })
        .then((response) => {
          
          this.$emit("update-product", response);

          this.newForm = {
            name: null,
            type: 'MANUAL',
            file: null,
          }

          this.showFilesModal = false;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false;
        });
    },
    handleDeleteFile(fileId) {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postProductDeleteFile(this.product.id, {
              file_id: fileId
            })
              .then((response) => {
                this.$emit("update-product", response);
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        },
      });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
