<template>
  <b-row>
    <b-col md="3">
      <b-table-simple bordered small>
        <b-tr>
          <b-td style="width: 80%;">
            <small>
              <strong>
               Tootjad
              </strong>
            </small>
          </b-td>
          <b-td class="text-center">
            <small>
              <b-badge variant="success">ok</b-badge>
            </small>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <small>
              <strong>
                Duplikaat laod?
              </strong>
            </small>
          </b-td>
          <b-td class="text-center">
            <small>
              <b-badge variant="danger">nok</b-badge>
            </small>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-col>
    <b-col>
      <b-table-simple bordered small>
        <b-tr v-for="product in batch.products" :key="`${batch.key}-${product.id}`">
          <b-td style="width: 40%;">
            <small>
              {{ product.name }}
              <br>
              <strong>
                {{ product.manufacturer }}
              </strong>
              <br>
              <strong>ID: </strong> {{ product.id }}
            </small>
            <div v-for="code in product.codes" :key="code.id">
              <small v-if="code.type == 'ean' || code.type == 'mpn'">
                <strong>{{ code.type }}</strong>: {{ code.code }}
              </small>
            </div>
          </b-td>
          <b-td>
            <b-table-simple bordered small class="mb-0">
              <b-tr v-for="stock in product.stocks" :key="`${product.id}-${stock.id}`">
                <b-td style="width: 20%;">
                  <small>{{ stock.supplier.name }}</small>
                </b-td>
                <b-td>
                  <small>{{ stock.original_manufacturer }}</small>
                </b-td>
                <b-td>
                  <small>{{ stock.original_name }}</small>
                </b-td>
                <b-td>
                  <small>{{ stock.import_id }}</small>
                </b-td>
              </b-tr>
            </b-table-simple>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
    }
  }
}
</script>