<template>
  <div class="d-relative">
    <loader :overlay="true" v-if="isLoading" />
    <h1>
      Tellimus #{{ orderId }}
      <b-button variant="primary" size="sm" @click.prevent="fetchOrder">
        <font-awesome-icon icon="sync" />
      </b-button>
    </h1>
    <template v-if="order">
      <b-row>
        <b-col md="8">
          <b-card header="" no-body>
            <b-card-header class="pr-1">
              Tellimuse andmed
              <b-button
                v-b-modal.order-info-1
                class="ml-2"
                size="sm"
                pill
                variant="outline-primary"
              >
                <font-awesome-icon class="mx-1" icon="info" />
              </b-button>
              <div class="float-right">
                <b-button
                  class="mr-1"
                  size="sm"
                  variant="outline-danger"
                  @click="editImportantMessage = !editImportantMessage"
                >
                  <span v-if="!editImportantMessage">Lisa muuda teadet</span>
                  <span v-else>Katkesta</span>
                </b-button>
              </div>
            </b-card-header>

            <div class="p-3">
              <b-form>
                <b-alert
                  variant="danger"
                  class="mb-3"
                  show
                  v-if="!order.is_paid"
                >
                  <strong>Tähelepanu! </strong>Tellimus on maksmata!
                </b-alert>
                <template v-if="!editImportantMessage">
                  <b-alert variant="danger" show v-if="order.important_message">
                    <span style="white-space: pre;">
                      {{ order.important_message }}
                    </span></b-alert
                  >
                </template>
                <template v-else>
                  <b-form-group class="mb-1" label="Meie teade">
                    <b-form-textarea
                      v-model="order.important_message"
                      rows="6"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </template>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Tellimuse keel">
                      <b-form-select
                        v-model="order.locale"
                        size="sm"
                        :options="selectLocales"
                      >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <div v-if="order.user">
                      <div class="mb-2">
                        Registreeritud klient
                      </div>

                      <router-link
                        :to="{
                          name: 'user.edit',
                          params: { userId: order.user.id },
                        }"
                      >
                        {{ order.user.first_name }}
                        {{ order.user.last_name }} (#{{ order.user.id }})
                      </router-link>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Eesnimi">
                      <b-form-input
                        v-model="order.first_name"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="mb-1" label="Perenimi">
                      <b-form-input
                        v-model="order.last_name"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-1" label="Email">
                      <b-form-input
                        v-model="order.email"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group class="mb-1" label="Telefon">
                      <b-form-input
                        v-model="order.phone"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template
                  v-if="order.customer_type == 2 || order.customer_type == 3"
                >
                  <b-row>
                    <b-col>
                      <b-form-group class="mb-1" label="Ettevõtte nimi">
                        <b-form-input
                          v-model="order.company"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>

                      <b-alert class="p-2" variant="info" show v-if="order.vat_validation">
                        <small>
                          <strong>VAT valideeritud:</strong> {{ order.vat_validation.request_date | moment("DD.MM.YYYY") }} <br>
                          <strong>Ettevõte:</strong> {{ order.vat_validation.name }} <br>
                          <strong>VAT:</strong> {{ order.vat_validation.vat }} <br>
                          <strong>Aadress:</strong> {{ order.vat_validation.address }} <br>
                        </small>
                      </b-alert>
                    </b-col>
                    <b-col>
                      <b-form-group class="mb-1" label="Ettevõtte reg nr">
                        <b-form-input
                          v-model="order.company_code"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="mb-1" label="KMKR nr">
                        <b-form-input
                          v-model="order.company_vat"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h6 class="mt-3 font-weight-bold">
                        Ettevõtte / Maksja aadress:
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group class="mb-1" label="Riik" v-if="countries">
                        <v-select
                          v-model="order.company_address.country"
                          :options="countries"
                          :reduce="(item) => item.code"
                          :clearable="false"
                        ></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group class="mb-1" label="Maakond">
                        <b-form-input
                          v-model="order.company_address.county"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group class="mb-1" label="Linn/Vald">
                        <b-form-input
                          v-model="order.company_address.city"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group class="mb-1" label="Postiindeks">
                        <b-form-input
                          v-model="order.company_address.zip"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group class="mb-1" label="Aadress">
                        <b-form-input
                          v-model="order.company_address.address"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col> </b-col>
                  </b-row>
                </template>

                <b-row class="mb-3">
                  <b-col>
                    <b-form-group class="mb-1" label="PDF Arve kommentaar">
                      <b-form-textarea
                        v-model="order.invoice_comment"
                        size="sm"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col class="text-right">
                    <b-button
                      variant="success"
                      size="sm"
                      @click.prevent="saveOrderData"
                    >
                      Salvesta kliendi andmed
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>

              <b-row class="mt-1">
                <b-col>
                  <strong>Maksemeetod:</strong>
                  {{ order.payment_type_name }}<br />

                  <ShippingMethodInfo
                    :order="order"
                    @startLoading="startLoading"
                    @stopLoading="stopLoading"
                  />
                </b-col>
                <b-col>
                  <strong>Tellimus loodud: </strong>
                  {{ orderCreatedDate }}<br />

                  <template v-if="order.is_waiting_payment">
                    <strong>Arve tähtaeg:</strong>
                    {{ order.payment_date | moment("DD.MM.YYYY") }} <br />
                    <br />
                  </template>

                  <EstimatedDeliveryDate
                    :order="order"
                    @startLoading="startLoading"
                    @stopLoading="stopLoading"
                  />

                  <strong>Tüüp:</strong> {{ order.customer_type_name }}<br />
                  <template
                    v-if="order.customer_type == 2 || order.customer_type == 3"
                  >
                    <strong>Soovib e-arvet: </strong>
                    <b-badge variant="success" v-if="order.einvoice">
                      Jah
                    </b-badge>
                    <b-badge variant="danger" v-else>
                      Ei
                    </b-badge>
                    <br />
                  </template>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <div v-html="order.delivery_data.additional_html"></div>
                </b-col>
                <b-col>
                  <strong>Kliendi kommentaar:</strong><br />
                  <b-alert variant="warning" v-if="order.comments" show>
                    {{ order.comments }}
                  </b-alert>
                </b-col>
              </b-row>

              <b-modal id="order-info-1" centered hide-footer size="lg" title="Tellimuse info">
                <div class="mb-2">
                  <strong>Tellimus</strong> #{{ order.id }} <br>
                  <strong>Eesnimi:</strong> {{ order.first_name }} <br>
                  <strong>Perenimi:</strong> {{ order.last_name }} <br>
                  <strong>Email:</strong> {{ order.email }} <br>
                  <strong>Telefon:</strong> {{ order.phone }} <br>
                  <div v-if="order.customer_type == 2 || order.customer_type == 3">
                    <strong>Ettevõtte nimi:</strong> {{ order.company }} <br>
                    <strong>Reg nr:</strong> {{ order.company_code }} <br>
                    <strong>KMKR nr:</strong> {{ order.company_vat }} <br>
                  </div>
                  <br>
                  <strong>Tarnemeetod:</strong> {{ order.shipping_method_name }} <br>
                  <span v-html="order.delivery_data.additional_html"></span> <br>
                  <br>
                  <div v-if="order.comments">
                    <strong>Kliendi kommentaar: </strong>
                    {{ order.comments }}
                    <br>
                  </div>
                  <strong>Tooted:</strong><br>
                  <div
                    class="mb-2"
                    v-for="product in order.product_data"
                    :product="product"
                    :key="`text-l${product.stock_id}`"
                  >
                    <a :href="`${webHostUrl}/et/toode/redirect-${product.sku}`">{{ product.name }}</a><br />
                    <b>Kogus: </b> {{ product.quantity }} <br />
                    <small>
                      <template v-if="product.ean">
                        <strong>EAN: </strong> {{ product.ean }} <br />
                      </template>
                      <template v-if="product.mpn">
                        <strong>MPN: </strong> {{ product.mpn }} <br />
                      </template>
                    </small>
                  </div>
                </div>
              </b-modal>
            </div>
          </b-card>

          <Products
            :order="order"
            @startLoading="startLoading"
            @stopLoading="stopLoading"
            @refreshOrder="fetchOrder"
          />

          <SupplierOrders
            :order="order"
            @startLoading="startLoading"
            @stopLoading="stopLoading"
            v-if="order.supplierOrders.length"
          />

          <StorageItems
            :order="order"
            v-if="order.storageItems.length"
          />

          <Logs :order="order" v-if="order.logs.length" />
        </b-col>
        <b-col md="4">
          <b-card class="mb-3" header="" no-body>
            <b-card-header class="pr-1">
              Tellimuse tegevused
              <div class="float-right">
                <Assignee v-model="assigneeId" @change="changeAssignee" />
              </div>
            </b-card-header>

            <b-table-simple bordered small class="mb-0">
              <b-tr v-if="order.referral">
                <b-td>
                  <small>
                    <strong>Referral:</strong>
                  </small>
                </b-td>
                <b-td class="text-right">
                  {{ order.referral }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Tellimus ilma KM-ta: </strong>
                  </small>
                  <b-badge variant="success" v-if="order.without_vat">Jah</b-badge>
                  <b-badge variant="danger" v-else>Ei</b-badge>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click.prevent="handleVatStatus"
                  >
                    <span v-if="!order.without_vat">Tee ilma KM-ta</span>
                    <span v-else>Tee koos KM-iga</span>
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Tellimus makstud: </strong>
                  </small>
                  <b-badge variant="success" v-if="order.is_paid">Jah</b-badge>
                  <b-badge variant="danger" v-else>Ei</b-badge>
                  <b-button
                    v-if="!order.is_paid && order.paymentLinks.length"
                    class="float-right"
                    size="sm"
                    variant="outline-warning"
                    v-b-tooltip.hover.noninteractive
                    title="Kopeeri makselink"
                    @click.prevent="copyPaymentLink()"
                  >
                    <font-awesome-icon :icon="['far', 'copy']" />
                  </b-button>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click.prevent="handleOrderPaidStatus"
                  >
                    <span v-if="!order.is_paid">Märgi makstuks</span>
                    <span v-else>Tühista maksmine</span>
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Tellimus eevasse saadetud: </strong>
                  </small>
                  <template v-if="order.eeva_order_sent_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{ order.eeva_order_sent_at | moment("DD.MM.YYYY HH:mm:ss") }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    @click.prevent="sendOrderToEeva"
                  >
                    <span>Saada tellimus</span>
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Arve eevasse saadetud: </strong>
                  </small>
                  <template v-if="order.eeva_invoice_sent_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{ order.eeva_invoice_sent_at | moment("DD.MM.YYYY HH:mm:ss") }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    @click.prevent="sendInvioceToEeva"
                  >
                    <span>Saada arve</span>
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Arve saadetud: </strong>
                  </small>
                  <template v-if="order.invoice_sent_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{
                        order.invoice_sent_at | moment("DD.MM.YYYY HH:mm:ss")
                      }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    @click.prevent="sendOrderInvoiceToCutomer"
                  >
                    Saada pdf arve kliendile
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Arve kinnitus saadetud: </strong>
                  </small>
                  <template v-if="order.confirmation_sent_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{
                        order.confirmation_sent_at
                          | moment("DD.MM.YYYY HH:mm:ss")
                      }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click.prevent="reSendConfirmation"
                  >
                    Saada tellimuse kinnitus
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Kreeditarve koostatud: </strong>
                  </small>
                  <template v-if="order.credit_invoice_created_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{
                        order.credit_invoice_created_at
                          | moment("DD.MM.YYYY HH:mm:ss")
                      }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click.prevent="createCreditInvoice"
                  >
                    Koosta kreeditarve
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <small>
                    <strong>Tellimus valmis kontoris: </strong>
                  </small>
                  <template v-if="order.arrived_stock_notification_sent_at">
                    <b-badge variant="success">Jah</b-badge>
                    <br />
                    <small>
                      {{
                        order.arrived_stock_notification_sent_at
                          | moment("DD.MM.YYYY HH:mm:ss")
                      }}
                    </small>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Ei</b-badge>
                  </template>
                </b-td>
                <b-td class="text-right">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="sendOrderArrivedOffice"
                  >
                    Saada teade
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td></b-td>
                <b-td class="text-right">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click.prevent="downloadOrderPdf"
                  >
                    Lae pdf arve alla
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td></b-td>
                <b-td class="text-right">
                  <router-link
                    class="btn btn-primary btn-sm"
                    :to="{ name: 'order.new', query: { orderId: order.id } }"
                  >
                    Koosta uus tellimus
                  </router-link>
                </b-td>
              </b-tr>
              <b-tr v-if="false">
                <b-td></b-td>
                <b-td class="text-right">
                  <CreateRma :order="order" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td></b-td>
                <b-td class="text-right">
                  <a :href="order.tracking_link" target="_blank">
                    Jälgi tellimust vaade
                  </a>
                </b-td>
              </b-tr>
            </b-table-simple>
          </b-card>

          <Delivery :order="order" @refreshOrder="fetchOrder" @startLoading="startLoading" @stopLoading="stopLoading" />

          <Status
            :order="order"
            @startLoading="startLoading"
            @stopLoading="stopLoading"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import moment from "moment";
import Products from "./order/Products";
import Status from "./order/Status";
import Delivery from "./order/Delivery";
import StorageItems from "./order/StorageItems";
import EstimatedDeliveryDate from "./order/EstimatedDeliveryDate";
import ShippingMethodInfo from "./order/ShippingMethodInfo";
import SupplierOrders from "./order/SupplierOrders";
import Logs from "./order/Logs";
import { mapActions, mapGetters } from "vuex";
// import fileDownload from "js-file-download";
import { successMessage } from "@/lib/messages";
import { errorMessage } from "@/lib/messages";
import { warningMessage } from "@/lib/messages";
import Assignee from "@/components/Assignee";
import CreateRma from "./order/CreateRma";

export default {
  components: {
    Products,
    Status,
    Delivery,
    StorageItems,
    EstimatedDeliveryDate,
    ShippingMethodInfo,
    SupplierOrders,
    Logs,
    Assignee,
    CreateRma
  },
  data() {
    return {
      isLoading: true,
      editImportantMessage: false,
      order: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      countries: "generic/countries",
      languages: "generic/languages",
      webHostUrl: "global/webHostUrl",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
    orderId() {
      if (!this.order) {
        return this.$route.params.orderId;
      }

      return this.order.id;
    },
    orderCreatedDate() {
      if (!this.order) {
        return null;
      }

      return moment(this.order.created_at).format("DD.MM.YYYY HH:mm:ss");
    },
    assigneeId() {
      if (this.order.adminUser) {
        return this.order.adminUser.id;
      }

      return null;
    },
  },
  mounted() {
    this.fetchOrderStatuses().then(() => {
      this.fetchOrder();
    });
    this.fetchCountries();
  },
  methods: {
    ...mapActions({
      fetchCountries: "generic/fetchCountries",
      fetchOrderStatuses: "generic/fetchOrderStatuses",
    }),
    fetchOrder() {
      this.isLoading = true;
      return RestService.getOrder(this.orderId)
        .then((response) => {
          this.order = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    saveOrderData() {
      this.isLoading = true;
      RestService.saveOrderData(this.order)
        .then((response) => {
          this.order = response.data.data;
          this.editImportantMessage = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    downloadOrderPdf() {
      RestService.downloadOrderPdf(this.order.id)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(response.data);
          return window.open(fileURL, this.order.order_pdf_name);
        })
        .catch((error) => {
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    sendOrderToEeva() {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            RestService.sendOrderToEeva(this.order.id)
              .then((response) => {
                if (response.data.status) {
                  successMessage(
                    "Teade!",
                    "Tellimus saadetakse peagi meritisse!"
                  );
                } else {
                  warningMessage("Tähelepanu!", response.data.message);
                }
              })
              .catch((e) => {
                errorMessage("Viga", e.response.data.message);
              });
          }
        },
      });
    },
    sendInvoiceToEeva() {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            RestService.sendOrderInvoiceToEeva(this.order.id)
              .then((response) => {
                if (response.data.status) {
                  successMessage(
                    "Teade!",
                    "Tellimus saadetakse peagi meritisse!"
                  );
                } else {
                  warningMessage("Tähelepanu!", response.data.message);
                }
              })
              .catch((e) => {
                errorMessage("Viga", e.response.data.message);
              });
          }
        },
      });
    },
    sendOrderInvoiceToCutomer() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            RestService.sendOrderInvoiceToCustomer(this.order.id)
              .then(() => {
                successMessage(
                  "Teade!",
                  "Tellimuse arve saadetakse peagi kliendile!"
                );
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    reSendConfirmation() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            RestService.reSendOrderConfirmation(this.order.id)
              .then(() => {
                successMessage(
                  "Teade!",
                  "Tellimuse kinnitus saadetakse peagi kliendile!"
                );
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    sendOrderArrivedOffice() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.sendOrderArrivedAtOffice(this.order.id)
              .then(() => {
                successMessage("Teade!", "Info saadetakse peagi kliendile!");
                this.fetchOrder();
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    createCreditInvoice() {
      this.$confirm({
        message: `Oled sa kindel? Sellega saadetakse ka kliendile PDF`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.createOrderCreditInvoice(this.order.id)
              .then(() => {
                successMessage("Teade!", "Info saadetakse peagi kliendile!");
                this.fetchOrder();
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response.data.message) {
                  errorMessage("Viga!", error.response.data.message);
                } else {
                  errorMessage("Viga!", error);
                }
              });
          }
        },
      });
    },
    changeAssignee(userId) {
      this.isLoading = true;
      RestService.postOrderSetAssignee(this.order.id, {
        admin_user_id: userId,
      })
        .then((response) => {
          this.order = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    handleOrderPaidStatus() {
      this.isLoading = true;
      RestService.postOrderSetPaid(this.order.id, {})
        .then((response) => {
          this.order = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    handleVatStatus() {
      this.isLoading = true;
      RestService.postOrderVatStatus(this.order.id, {})
        .then((response) => {
          this.order = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga!", error.response.data.message);
          } else {
            errorMessage("Viga!", error);
          }
        });
    },
    copyPaymentLink() {
      if (!this.order.paymentLinks.length) {
        return null;
      }

      let url = this.order.paymentLinks[0].link;

      this.$clipboard(url);
      successMessage("Teade!", "Kopeeritud!");
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading(newOrder) {
      if (newOrder) {
        this.order = newOrder;
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
