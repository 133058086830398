<template>
  <div>
    <b-button
      class="float-right"
      variant="primary"
      size="sm"
      @click.prevent="openCodesModal"
    >
      Halda toote koode
    </b-button>

    <b-modal
      v-model="showCodesModal"
      hide-footer
      size="lg"
      title="Toote koodid"
    >
      <loader :overlay="true" v-if="isLoading" />

      <b-row>
        <b-col md="6">
          <strong>Lisa uus kood</strong> <br />
          <b-form-group label="Koodi tüüp">
            <b-form-select
              v-model="type"
              :options="typeOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Kood">
            <b-form-input v-model="code" size="sm"></b-form-input>
          </b-form-group>

          <b-button
            class="float-right"
            variant="primary"
            @click.prevent="checkCodes"
          >
            Kontrolli
          </b-button>
        </b-col>
        <b-col md="6">
          <strong>Olemas olevad toote koodid</strong> <br />

          <b-table-simple v-if="product.codes.length" bordered small>
            <b-tr v-for="code in product.codes" :key="code.id">
              <b-td>{{ code.type }}</b-td>
              <b-td>{{ code.code }}</b-td>
              <b-td class="text-right" style="width: 10%">
                <b-button
                  size="sm"
                  variant="danger"
                  @click.prevent="deleteCode(code.id)"
                >
                  <font-awesome-icon icon="times" />
                </b-button>
              </b-td>
            </b-tr>
          </b-table-simple>
          <b-alert v-else variant="warning" show>
            Tootel puuduvad koodid
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-if="showResult">
        <b-col>
          <strong>Kontrolli tulemus</strong> <br />
          <b-alert v-if="errorMesage" variant="danger" show>
            {{ errorMesage }} <br />
            <span v-for="p in duplicateProducts" :key="p.id">
              Toote ID: <b>{{ p.id }}</b> Nimi: <b>{{ p.name }}</b> <br />
            </span>
          </b-alert>
          <b-alert v-else variant="success" show
            >Konflikte ei leitud koodiga.</b-alert
          >

          <template v-if="canAddCode">
            <b-button
              v-if="errorMesage"
              variant="warning"
              @click.prevent="saveCode"
            >
              Tean riske ja lisan koodi tootele!
            </b-button>
            <b-button v-else variant="success" @click.prevent="saveCode">
              Lisa kood tootele
            </b-button>
          </template>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      canAddCode: false,
      showResult: false,
      errorMesage: null,
      duplicateProducts: [],
      showCodesModal: false,
      type: "ean",
      code: null,
      typeOptions: [
        { value: "ean", text: "EAN" },
        { value: "mpn", text: "MPN (Manufacturer code)" },
      ],
    };
  },
  methods: {
    openCodesModal() {
      this.showCodesModal = true;
    },
    checkCodes() {
      this.isLoading = true;
      this.errorMesage = null;
      this.duplicateProducts = [];
      this.showResult = false;
      this.canAddCode = false;

      return RestService.postCheckProductCodes(this.product.id, {
        type: this.type,
        code: this.code,
      })
        .then(() => {
          this.isLoading = false;
          this.canAddCode = true;
          this.showResult = true;
        })
        .catch((error) => {
          this.errorMesage = error.response.data.message;
          if (error.response.data.data) {
            this.duplicateProducts = error.response.data.data;
          }
          if (error.response.data.canAddCode) {
            this.canAddCode = error.response.data.canAddCode;
          }

          this.showResult = true;
          this.isLoading = false;
        });
    },
    saveCode() {
      this.isLoading = true;

      return RestService.postAddProductCode(this.product.id, {
        type: this.type,
        code: this.code,
      })
        .then(() => {
          this.$emit("reloadProduct");
          this.showCodesModal = false;
          this.isLoading = false;
          this.errorMesage = null;
          this.duplicateProducts = [];
          this.showResult = false;
          this.canAddCode = false;
        })
        .catch((error) => {
          this.errorMesage = error.response.data.message;
          this.duplicateProducts = [];
          this.isLoading = false;
        });
    },
    deleteCode(codeId) {
      this.isLoading = true;

      return RestService.postDeleteProductCode(this.product.id, {
        id: codeId,
      })
        .then(() => {
          this.$emit("reloadProduct");
          this.showCodesModal = false;
          this.isLoading = false;
          this.errorMesage = null;
          this.duplicateProducts = [];
          this.showResult = false;
          this.canAddCode = false;
        })
        .catch((error) => {
          this.errorMesage = error.response.data.message;
          this.duplicateProducts = [];
          this.isLoading = false;
        });
    },
  },
};
</script>
