<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Lisa uus administraator
    </h2>

    <b-row>
      <b-col md="6">
        <b-card header="Uue administraatori info">
          <b-form>
            <b-form-group class="mb-1" label="Nimi">
              <b-form-input
                v-model="form.name"
                size="sm"
                :state="getFieldStateFromErrors('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("name") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Email">
              <b-form-input
                type="email"
                v-model="form.email"
                size="sm"
                :state="getFieldStateFromErrors('email')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("email") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-1" label="Parool">
              <b-form-input
                type="password"
                v-model="form.password"
                size="sm"
                :state="getFieldStateFromErrors('password')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ getFieldError("password") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="text-right mt-3">
              <b-button
                type="submit"
                variant="success"
                @click.prevent="saveAdminUser"
              >
                Salvesta
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    saveAdminUser() {
      this.isLoading = true;

      let payload = this.form;

      RestService.postSaveNewAdminUser(payload)
        .then((response) => {
          this.$router.push({
            name: "adminUsers.edit",
            params: { adminUserId: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
