<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1 class="mb-4">Kullerite staatused</h1>

    <b-row>
      <b-col class="mb-3" md="3" v-for="courier in couriers" :key="courier.id">
        <CourierStatus
          style="cursor: pointer;"
          :variant="courier.is_ordered ? 'success' : 'danger'"
          @update="updateCourierStatus(courier.id)"
        >
          {{ courier.name }}
        </CourierStatus>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import CourierStatus from "./CourierStatus";
import { errorMessage } from "@/lib/messages";

export default {
  components: {
    CourierStatus,
  },
  data() {
    return {
      isLoading: false,
      couriers: [],
    };
  },
  mounted() {
    this.fetchCourierStatuses();

    // if (window.Echo) {
    //   window.Echo.private("MiscChannel").listen(
    //     ".courier-status-changed",
    //     () => {
    //       this.fetchCourierStatuses();
    //     }
    //   );
    // }
  },
  methods: {
    fetchCourierStatuses() {
      this.isLoading = true;
      RestService.getCourierStatuses()
        .then((response) => {
          this.couriers = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
    updateCourierStatus(id) {
      this.isLoading = true;
      RestService.postCourierStatusUpdate(id)
        .then(() => {})
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.message) {
            errorMessage("Viga", error.response.data.message);
          }
        });
    },
  },
};
</script>
