<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Blogi
      <router-link
        class="btn btn-primary btn-sm"
        :to="{
          name: 'blog.new',
        }"
      >
        Lisa uus
      </router-link>
    </h1>

    <b-table-simple bordered responsive small class="mb-0" v-if="posts">
      <b-tr variant="primary">
        <b-th style="width: 1%;" class="text-center">
          #
        </b-th>
        <b-th>
          Nimi
        </b-th>
        <b-th style="width: 15%;" class="text-center">Aktiivne</b-th>
        <b-th style="width: 15%;" class="text-center">Avaldamise kuupäev</b-th>
        <b-th style="width: 10%;"></b-th>
      </b-tr>

      <b-tr v-for="(post, postKey) in posts" :key="post.id">
        <b-td class="text-center">
          {{ (postKey + 1) * currentPage }}
        </b-td>
        <b-td>
          {{ post.title }}
        </b-td>
        <b-td class="text-center">
          <font-awesome-icon
            class="text-success"
            icon="check"
            v-if="post.is_active"
          />
          <font-awesome-icon class="text-danger" icon="times" v-else />
        </b-td>
        <b-td class="text-center">
          {{ post.published_at | moment("DD.MM.YYYY") }}
          <small>
            {{ post.published_at | moment("HH:mm") }}
          </small>
        </b-td>
        <b-td class="text-right">
          <router-link
            class="btn btn-warning btn-sm mr-2"
            :to="{
              name: 'blog.edit',
              params: { postId: post.id },
            }"
          >
            Muuda
          </router-link>

          <b-button
            variant="danger"
            size="sm"
            @click.prevent="deletePost(post)"
          >
            X
          </b-button>
        </b-td>
      </b-tr>
    </b-table-simple>

    <template v-if="pagination">
      <small>Kokku: {{ pagination.total }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="pageChange"
      ></b-pagination>
    </template>

  </div>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      posts: null,
      pagination: null,
      currentPage: 1,
    };
  },
  computed: {

  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    fetchPosts(page = 1) {
      this.isLoading = true;
      RestService.getBlogPosts({
        page: page,
      }).then((resposne) => {
        this.posts = resposne.data.data;
        this.pagination = resposne.data.meta.pagination;
        this.isLoading = false;
      });
    },
    deletePost(post) {
      this.$confirm({
        message: `Kas oled kindel?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            RestService.postBlogPostDelete(post.id).then(() => {
              this.fetchPosts();
            });
          }
        },
      });
    },
    pageChange(page) {
      this.fetchPosts(page);
    },
  },
};
</script>
