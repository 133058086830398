<template>
  <b-card class="mb-3" header="" no-body>
    <b-card-header class="pr-1">
      Transport
      <div class="float-right">
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openCourier"
          v-if="order.shipping_method.type == 'courier'"
        >
          Kuller
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openDpd"
          v-if="order.shipping_method.type == 'dpd-parcel'"
        >
          DPD Pakiautomaat
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openSmartpost"
          v-if="order.shipping_method.type == 'smartpost-parcel'"
        >
          SmartPost Pakiautomaat
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openOmniva"
          v-if="order.shipping_method.type == 'omniva-parcel'"
        >
          Omniva Pakiautomaat
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openSmartpostFi"
          v-if="order.shipping_method.type == 'smartpost-parcel-fi'"
        >
          SmartPost FI Pakiautomaat
        </b-button>

        <b-button
          class="mr-1"
          size="sm"
          variant="outline-success"
          @click="openVenipak"
          v-if="order.shipping_method.type == 'venipak-parcel'"
        >
          Venipak Pakiautomaat
        </b-button>

        <b-dropdown variant="outline-primary" size="sm" text="Muu" right>
          <b-dropdown-item-button @click.prevent="openCourier">
            Kuller
          </b-dropdown-item-button>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button @click.prevent="openDpd">
            DPD pakiautomaat <b-badge variant="light" small>EE</b-badge>
          </b-dropdown-item-button>
          <!-- <b-dropdown-item-button @click.prevent="openDpd">
            DPD pakiautomaat <b-badge variant="light" small>FI</b-badge>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.prevent="openDpd">
            DPD pakiautomaat <b-badge variant="light" small>LV</b-badge>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.prevent="openDpd">
            DPD pakiautomaat <b-badge variant="light" small>LT</b-badge>
          </b-dropdown-item-button> -->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button @click.prevent="openSmartpost">
            SmartPost pakiautomaat <b-badge variant="light" small>EE</b-badge>
          </b-dropdown-item-button>
          <!-- <b-dropdown-item-button @click.prevent="openSmartpostFi">
            SmartPost pakiautomaat <b-badge variant="light" small>FI</b-badge>
          </b-dropdown-item-button> -->
          <!-- <b-dropdown-item-button @click.prevent="openSmartpost">
            SmartPost pakiautomaat <b-badge variant="light" small>LV</b-badge>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.prevent="openSmartpost">
            SmartPost pakiautomaat <b-badge variant="light" small>LT</b-badge>
          </b-dropdown-item-button> -->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button @click.prevent="openOmniva">
            Omniva pakiautomaat <b-badge variant="light" small>EE</b-badge>
          </b-dropdown-item-button>
          <!-- <b-dropdown-item-button @click.prevent="openOmniva">
            Omniva pakiautomaat <b-badge variant="light" small>LV</b-badge>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.prevent="openOmniva">
            Omniva pakiautomaat <b-badge variant="light" small>LT</b-badge>
          </b-dropdown-item-button> -->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button @click.prevent="openVenipak">
            Venipak pakiautomaat <b-badge variant="light" small>EE</b-badge>
          </b-dropdown-item-button>
          <!-- <b-dropdown-item-button @click.prevent="openVenipak">
            Venipak pakiautomaat <b-badge variant="light" small>LV</b-badge>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click.prevent="openVenipak">
            Venipak pakiautomaat <b-badge variant="light" small>LT</b-badge>
          </b-dropdown-item-button> -->
        </b-dropdown>
      </div>
    </b-card-header>

    <div class="p-2">
      <b-table-simple class="mb-0" bordered small v-if="order.tracking_info">
        <b-tr variant="primary">
          <b-td style="width: 1%;">Tüüp</b-td>
          <b-td>Jälgimiskood</b-td>
          <b-td style="width: 1%;" class="text-center">PDF</b-td>
          <b-td style="width: 1%;" class="text-center">
            <span id="delivery-notify-tooltip" class="d-inline-block" tabindex="0">
              <font-awesome-icon :icon="['far', 'bell']"/>
            </span>
            <b-tooltip target="delivery-notify-tooltip" noninteractive>Klienti teavitatud</b-tooltip>
          </b-td>
        </b-tr>
        <b-tr
          v-for="(tracking, trackingKey) in order.tracking_info"
          :key="trackingKey"
        >
          <b-td>
            <small>{{ tracking.type }}</small>
          </b-td>
          <b-td>
            <b-button
              class="float-right ml-1"
              v-b-tooltip.hover.noninteractive
              title="Saada kliendile teade"
              size="sm"
              variant="outline-warning"
              @click.prevent="sendTrackingNotification(tracking)"
            >
              <font-awesome-icon :icon="['far', 'bell']" />
            </b-button>
            <b-button
              class="float-right"
              v-b-tooltip.hover.noninteractive
              title="Ava uues vaheaknas"
              size="sm"
              variant="outline-primary"
              v-if="canOpenLink(tracking)"
              :href="getLink(tracking)"
              target="_blank"
            >
              <font-awesome-icon icon="external-link-alt" />
            </b-button>
            <b-button
              class="float-right"
              :class="{ 'mr-1': canOpenLink(tracking) }"
              size="sm"
              variant="outline-success"
              v-b-tooltip.hover.noninteractive
              title="Kopeeri"
              @click.prevent="copyTracking(tracking)"
            >
              <font-awesome-icon :icon="['far', 'copy']" />
            </b-button>
            <small>
              <template
                v-if="tracking.type == 'venipak' && tracking.package_number"
              >
                {{ tracking.package_number }} <br />
              </template>
              <template v-else>{{ tracking.code }}<br /></template>
              <small v-if="tracking.created_at">
                {{ tracking.created_at | moment("DD.MM.YYYY HH:mm") }}
              </small>
            </small>
          </b-td>
          <b-td class="text-right">
            <b-button
              size="sm"
              variant="outline-primary"
              v-b-tooltip.hover.noninteractive
              title="Lae alla"
              @click.prevent="downloadLabel(tracking)"
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </b-td>
          <b-td>
            <b-badge variant="success" v-if="tracking.is_notification_sent">Jah</b-badge>
            <b-badge v-else>Ei</b-badge>
          </b-td>
        </b-tr>
      </b-table-simple>
      <b-alert class="mb-0" variant="info" v-else show>
        Pakendikaarte pole veel loodud!
      </b-alert>

      <b-modal size="lg" v-model="showCourier" hide-footer title="Kuller">
        <loader :overlay="true" v-if="isCourierLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="Riik" v-if="countries">
              <v-select
                v-model="courierData.country_code"
                :options="countries"
                :reduce="(item) => item.code"
                :clearable="false"
              ></v-select>
            </b-form-group>

            <b-form-group class="mb-1" label="Linn/Vald">
              <b-form-input v-model="courierData.city" size="sm"></b-form-input>
            </b-form-group>

            <b-form-group class="mb-1" label="Aadress">
              <b-form-input
                v-model="courierData.address"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input
                v-model="courierData.phone"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Maakond">
              <b-form-input
                v-model="courierData.county"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Postiindeks">
              <b-form-input v-model="courierData.zip" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Paki kaal">
              <b-form-input
                v-model="courierData.weight"
                size="sm"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="mb-1" label="Kommentaar">
              <b-form-textarea
                v-model="courierData.comment"
                size="sm"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="courierData.type == 'venipak'">
          <b-col md="4">
            <b-form-group class="mb-1" label="Length (Meters)">
              <b-form-input
                v-model="courierData.length"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-1" label="Width (Meters)">
              <b-form-input
                v-model="courierData.width"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group class="mb-1" label="Height (Meters)">
              <b-form-input
                v-model="courierData.height"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="mb-1" label="Pakkide arv">
              <b-form-input
                v-model="courierData.total_packages"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6">
            <b-form-radio-group size="sm" v-model="courierData.type">
              <b-form-radio value="venipak">Venipak</b-form-radio>
              <b-form-radio value="dpd">DPD</b-form-radio>
              <b-form-radio value="smartpost">Smartpost</b-form-radio>
              <!-- <b-form-radio value="sendpack">Sendpack</b-form-radio> -->
              <b-form-radio value="osc">OSC</b-form-radio>
              <b-form-radio value="pureva">Pureva</b-form-radio>
            </b-form-radio-group>

            <template v-if="courierData.type == 'sendpack'">
              <b-form-group class="mb-1" label="Paki suurus">
                <v-select
                  v-model="courierData.pack_type"
                  :options="sendpackSizes"
                  :reduce="(item) => item.code"
                  :clearable="false"
                ></v-select>
              </b-form-group>
            </template>
          </b-col>
        </b-row>

        <div class="text-right">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="courierData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="courierData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateCourier">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal size="lg" v-model="showDpd" hide-footer title="DPD Pakiautomaat">
        <loader :overlay="true" v-if="isDpdLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="DPD Pakiautomaat">
              <v-select
                v-model="dpdData.parcel_id"
                :options="selectDpdParcel"
                :reduce="(item) => item.code"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input v-model="dpdData.phone"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="dpdData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="dpdData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateDpd">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal
        size="lg"
        v-model="showSmartpost"
        hide-footer
        title="SmartPost Pakiautomaat"
      >
        <loader :overlay="true" v-if="isSmartpostLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="SmartPost Pakiautomaat">
              <v-select
                v-model="smartpostData.parcel_id"
                :options="selectSmartpostParcel"
                :reduce="(item) => item.code"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input v-model="smartpostData.phone"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="mb-1" label="Pakkide arv">
              <b-form-input
                v-model="smartpostData.total_packages"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="smartpostData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="smartpostData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateSmartpost">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal
        size="lg"
        v-model="showSmartpostFi"
        hide-footer
        title="SmartPost FI Pakiautomaat"
      >
        <loader :overlay="true" v-if="isSmartpostFiLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="SmartPost FI Pakiautomaat">
              <v-select
                v-model="smartpostFiData.parcel_id"
                :options="selectSmartpostFiParcel"
                :reduce="(item) => item.code"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input v-model="smartpostFiData.phone"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="mb-1" label="Pakkide arv">
              <b-form-input
                v-model="smartpostFiData.total_packages"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="smartpostFiData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="smartpostFiData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateSmartpostFi">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal
        size="lg"
        v-model="showOmniva"
        hide-footer
        title="Omniva Pakiautomaat"
      >
        <loader :overlay="true" v-if="isOmnivaLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="Omniva Pakiautomaat">
              <v-select
                v-model="omnivaData.parcel_id"
                :options="selectOmnivaParcel"
                :reduce="(item) => item.code"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input v-model="omnivaData.phone"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="omnivaData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="omnivaData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateOmniva">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal
        size="lg"
        v-model="showVenipak"
        hide-footer
        title="Venipak Pakiautomaat"
      >
        <loader :overlay="true" v-if="isVenipakLoading" />
        <b-row>
          <b-col md="6">
            <b-form-group class="mb-1" label="Venipak Pakiautomaat">
              <v-select
                v-model="venipakData.parcel_id"
                :options="selectVenipakParcel"
                :reduce="(item) => item.code"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="mb-1" label="Telefon">
              <b-form-input v-model="venipakData.phone"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="mb-1" label="Pakkide arv">
              <b-form-input
                v-model="venipakData.total_packages"
                size="sm"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right mt-3">
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="venipakData.notify_client"
              :value="true"
              :unchecked-value="false"
            >
              Teavita klienti
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-form-checkbox
              v-model="venipakData.update_order_status"
              :value="true"
              :unchecked-value="false"
            >
              Uuenda tellimuse staatust
            </b-form-checkbox>
          </b-form-group>

          <b-button variant="success" @click.prevent="generateVenipak">
            Koosta pakikaart
          </b-button>
        </div>
      </b-modal>

      <b-modal
        size="lg"
        v-model="showPrintingLabel"
        hide-footer
        title="PDF"
      >
        <div style="min-height: 500px;">
          <loader :overlay="true" v-if="isPrintingLabelLoading" />
          
          <iframe
            v-if="printingLabelUrl"
            :src="printingLabelUrl"
            width="100%"
            height="700px"
            ref="label_print_frame"
            @load="openPrintLabelDialog"
            frameborder="0">
          </iframe>
        </div>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";
import { errorMessage } from "@/lib/messages";
import { successMessage } from "@/lib/messages";
import { mapGetters } from "vuex";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPrintingLabel: false,
      isPrintingLabelLoading: false,
      printingLabelUrl: null,
      sendpackSizes: [
        {
          code: "cll",
          label: "CLL",
        },
        {
          code: "pll",
          label: "PLL",
        },
        {
          code: "eur",
          label: "EUR",
        },
        {
          code: "fin",
          label: "FIN",
        },
        {
          code: "poolalus",
          label: "Poolalus",
        },
      ],
      isCourierLoading: false,
      showCourier: false,
      courierData: {
        type: "venipak",
        country_code: "EE",
        county: null,
        city: null,
        zip: null,
        address: null,
        phone: null,
        comment: null,
        weight: 1,
        length: 0.1,
        width: 0.1,
        height: 0.1,
        pack_type: "cll",
        total_packages: 1,
        notify_client: false,
        update_order_status: true,
      },
      showDpd: false,
      isDpdLoading: false,
      dpdParcels: null,
      dpdData: {
        parcel_id: null,
        phone: null,
        notify_client: false,
        update_order_status: true,
      },
      showSmartpost: false,
      isSmartpostLoading: false,
      smartpostParcels: null,
      smartpostData: {
        parcel_id: null,
        phone: null,
        total_packages: 1,
        notify_client: false,
        update_order_status: true,
      },
      showSmartpostFi: false,
      isSmartpostFiLoading: false,
      smartpostParcelsFi: null,
      smartpostFiData: {
        parcel_id: null,
        phone: null,
        total_packages: 1,
        notify_client: false,
        update_order_status: true,
      },
      showOmniva: false,
      isOmnivaLoading: false,
      omnivaParcels: null,
      omnivaData: {
        parcel_id: null,
        phone: null,
        notify_client: false,
        update_order_status: true,
      },
      showVenipak: false,
      isVenipakLoading: false,
      venipakParcels: null,
      venipakData: {
        parcel_id: null,
        phone: null,
        total_packages: 1,
        notify_client: false,
        update_order_status: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: "generic/countries",
    }),
    selectDpdParcel() {
      if (!this.dpdParcels) {
        return [];
      }

      let data = [];

      for (let property in this.dpdParcels) {
        this.dpdParcels[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectSmartpostParcel() {
      if (!this.smartpostParcels) {
        return [];
      }

      let data = [];

      for (let property in this.smartpostParcels) {
        this.smartpostParcels[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectSmartpostFiParcel() {
      if (!this.smartpostParcelsFi) {
        return [];
      }

      let data = [];

      for (let property in this.smartpostParcelsFi) {
        this.smartpostParcelsFi[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectOmnivaParcel() {
      if (!this.omnivaParcels) {
        return [];
      }

      let data = [];

      for (let property in this.omnivaParcels) {
        this.omnivaParcels[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
    selectVenipakParcel() {
      if (!this.venipakParcels) {
        return [];
      }

      let data = [];

      for (let property in this.venipakParcels) {
        this.venipakParcels[property].forEach((i) => {
          data.push({
            code: i.id,
            label: i.name,
          });
        })
      }

      return data;
    },
  },
  methods: {
    sendTrackingNotification(tracking) {
      this.$confirm({
        message: `Kas sa oled kindel, et tahad kliendile teavituse saata?`,
        button: {
          no: "Ei",
          yes: "Jah",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit('startLoading');

            RestService.postOrderNotifyTracking(this.order.id, {
              tracking: tracking
            })
            .then(() => {
              this.$emit("refreshOrder");
            })
            .catch((error) => {
              if (error.response) {
                errorMessage("Viga!", error.response.data.message);
              }
              this.$emit('stopLoading');
            });
          }
        },
      });
    },
    copyTracking(tracking) {
      let url = "";

      if (tracking.type == "venipak" || tracking.type == "venipak-parcel") {
        if (tracking.package_number) {
          url = `https://venipak.ee/tracking/track/${tracking.package_number}`;
        } else {
          url = `https://venipak.ee/tracking/track/${tracking.code}`;
        }
      } else if (tracking.type == "sendpack") {
        url = `Sendpack.ee kulleriga teele saadetud. Kulleri tellimuse number ${tracking.code} www.sendpack.ee | info@sendpack.ee | +372 621 1661 Juhul kui soovite paki kohta rohkem informatsiooni siis palume helistada Sendpack-i klienditoele ning esitage Sendpack.ee kullertellimuse number.`;
      } else if (tracking.type == "dpd" || tracking.type == "dpd_parcel") {
        url = `https://www.dpdgroup.com/ee/mydpd/my-parcels/incoming?parcelNumber=${tracking.code}`;
      } else if (tracking.type == "smartpost") {
        url = `https://itella.ee/eraklient/saadetise-jalgimine/?trackingCode=${tracking.code}`;
      } else if (tracking.type == "smartpost-fi") {
        url = `https://itella.ee/en/private-customer/parcel-tracking/?trackingCode=${tracking.code}`;
      } else if (tracking.type == "omniva") {
        url = `https://minu.omniva.ee/track/${tracking.code}/?language=et`;
      }

      this.$clipboard(url);
      successMessage("Teade!", "Kopeeritud!");
    },
    getLink(tracking) {
      if (tracking.type == "venipak" || tracking.type == "venipak-parcel") {
        if (tracking.package_number) {
          return `https://venipak.ee/tracking/track/${tracking.package_number}`;
        } else {
          return `https://venipak.ee/tracking/track/${tracking.code}`;
        }
      } else if (tracking.type == "dpd" || tracking.type == "dpd_parcel") {
        return `https://www.dpdgroup.com/ee/mydpd/my-parcels/incoming?parcelNumber=${tracking.code}`;
      } else if (tracking.type == "smartpost") {
        return `https://itella.ee/eraklient/saadetise-jalgimine/?trackingCode=${tracking.code}`;
      } else if (tracking.type == "smartpost-fi") {
        return `https://itella.ee/en/private-customer/parcel-tracking/?trackingCode=${tracking.code}`;
      } else if (tracking.type == "omniva") {
        return `https://minu.omniva.ee/track/${tracking.code}/?language=et`;
      }

      return "#";
    },
    canOpenLink(tracking) {
      if (tracking.type == "venipak" || tracking.type == "venipak-parcel") {
        return true;
      }

      if (tracking.type == "dpd" || tracking.type == "dpd_parcel") {
        return true;
      }

      if (tracking.type == "omniva") {
        return true;
      }

      if (tracking.type == "smartpost" || tracking.type == "smartpost-fi") {
        return true;
      }

      return false;
    },
    downloadLabel(tracking) {
      this.showPrintingLabel = true;
      this.isPrintingLabelLoading = true;
      this.printingLabelUrl = null;

      let code;
      let type = tracking.type;

      code = tracking.code;

      if (tracking.type == "venipak" || tracking.type == "venipak-parcel") {
        if (tracking.package_number) {
          code = tracking.package_number;
        } else {
          code = tracking.code;
        }
      }

      if (tracking.type == "dpd_parcel") {
        type = "dpd";
      }

      if (tracking.type == "venipak-parcel") {
        type = "venipak";
      }

      RestService.downloadTrackingLabelPdf(this.order.id, type, code)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(response.data);
          this.printingLabelUrl = fileURL;
          this.isPrintingLabelLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
        });
    },
    openPrintLabelDialog() {
      if (this.showPrintingLabel) {
        this.$refs.label_print_frame.contentWindow.print();
      }
    },
    openCourier() {
      this.courierData.phone = this.order.phone;

      if (this.order.shipping_method.type == 'courier') {
        this.courierData.country_code = this.order.delivery_data.additional_data.country_code;
        this.courierData.county = this.order.delivery_data.additional_data.county;
        this.courierData.city = this.order.delivery_data.additional_data.city;
        this.courierData.zip = this.order.delivery_data.additional_data.zip;
        this.courierData.address = this.order.delivery_data.additional_data.address;
      }

      this.showCourier = true;
    },
    generateCourier() {
      this.isCourierLoading = true;
      RestService.postGenerateCourier(this.order.id, this.courierData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showCourier = false;
          this.isCourierLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isCourierLoading = false;
        });
    },
    generateDpd() {
      this.isDpdLoading = true;
      RestService.postOrderGenerateDpd(this.order.id, this.dpdData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showDpd = false;
          this.isDpdLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isDpdLoading = false;
        });
    },
    generateSmartpost() {
      this.isSmartpostLoading = true;
      RestService.postOrderGenerateSmartpost(this.order.id, this.smartpostData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showSmartpost = false;
          this.isSmartpostLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isSmartpostLoading = false;
        });
    },
    generateSmartpostFi() {
      this.isSmartpostFiLoading = true;
      RestService.postOrderGenerateSmartpostFi(this.order.id, this.smartpostFiData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showSmartpostFi = false;
          this.isSmartpostFiLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isSmartpostFiLoading = false;
        });
    },
    generateOmniva() {
      this.isOmnivaLoading = true;
      RestService.postOrderGenerateOmniva(this.order.id, this.omnivaData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showOmniva = false;
          this.isOmnivaLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isOmnivaLoading = false;
        });
    },
    generateVenipak() {
      this.isVenipakLoading = true;
      RestService.postOrderGenerateVenipak(this.order.id, this.venipakData)
        .then(() => {
          this.$emit("refreshOrder");
          this.showVenipak = false;
          this.isVenipakLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            errorMessage("Viga!", error.response.data.message);
          }
          this.isVenipakLoading = false;
        });
    },
    openDpd() {
      this.fetchDpdParcels();
      this.dpdData.phone = this.order.phone;
      if (this.order.shipping_method.type == 'dpd-parcel') {
        this.dpdData.parcel_id = this.order.delivery_data.additional_data.id;
      }

      this.showDpd = true;
    },
    openSmartpost() {
      this.fetchSmartpostParcels();
      this.smartpostData.phone = this.order.phone;
      if (this.order.shipping_method.type == 'smartpost-parcel') {
        this.smartpostData.parcel_id = this.order.delivery_data.additional_data.id;
      }

      this.showSmartpost = true;
    },
    openSmartpostFi() {
      this.fetchSmartpostParcelsFi();
      this.smartpostFiData.phone = this.order.phone;
      if (this.order.shipping_method.type == 'smartpost-parcel-fi') {
        this.smartpostFiData.parcel_id = this.order.delivery_data.additional_data.id;
      }

      this.showSmartpostFi = true;
    },
    openOmniva() {
      this.fetchOmnivaParcels();
      this.omnivaData.phone = this.order.phone;
      if (this.order.shipping_method.type == 'omniva-parcel') {
        this.omnivaData.parcel_id = this.order.delivery_data.additional_data.id;
      }

      this.showOmniva = true;
    },
    openVenipak() {
      this.fetchVenipakParcels();
      this.venipakData.phone = this.order.phone;
      if (this.order.shipping_method.type == 'venipak-parcel') {
        this.venipakData.parcel_id = this.order.delivery_data.additional_data.id;
      }

      this.showVenipak = true;
    },
    fetchDpdParcels() {
      this.isDpdLoading = true;
      RestService.getDpdParcels().then((response) => {
        this.dpdParcels = response.data.data;
        if (this.order.shipping_method.type == 'dpd-parcel') {
          this.dpdData.parcel_id = this.order.delivery_data.additional_data.id;
        }
        this.isDpdLoading = false;
      });
    },
    fetchSmartpostParcels() {
      this.isSmartpostLoading = true;
      RestService.getSmartPostParcels().then((response) => {
        this.smartpostParcels = response.data.data;
        if (this.order.shipping_method.type == 'smartpost-parcel') {
          this.smartpostData.parcel_id = this.order.delivery_data.additional_data.id;
        }
        this.isSmartpostLoading = false;
      });
    },
    fetchSmartpostParcelsFi() {
      this.isSmartpostFiLoading = true;
      RestService.getSmartPostParcelsFi().then((response) => {
        this.smartpostParcelsFi = response.data.data;
        if (this.order.shipping_method.type == 'smartpost-parcel-fi') {
          this.smartpostFiData.parcel_id = this.order.delivery_data.additional_data.id;
        }
        this.isSmartpostFiLoading = false;
      });
    },
    fetchOmnivaParcels() {
      this.isOmnivaLoading = true;
      RestService.getOmnivaParcels().then((response) => {
        this.omnivaParcels = response.data.data;
        if (this.order.shipping_method.type == 'omniva-parcel') {
          this.omnivaData.parcel_id = this.order.delivery_data.additional_data.id;
        }
        this.isOmnivaLoading = false;
      });
    },
    fetchVenipakParcels() {
      this.isVenipakLoading = true;
      RestService.getVenipakParcels().then((response) => {
        this.venipakParcels = response.data.data;
        if (this.order.shipping_method.type == 'venipak-parcel') {
          this.venipakData.parcel_id = this.order.delivery_data.additional_data.id;
        }
        this.isVenipakLoading = false;
      });
    },
  },
};
</script>
