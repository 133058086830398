<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Audit logi
      <small v-if="adminUser">- {{ adminUser.name }}</small>
    </h2>

    <b-alert variant="danger" v-if="showErrorMessage" show>
      Teil puudub õigus siin lehel tegutseda!
    </b-alert>

    <b-card class="mt-3" header="Audit logi" no-body>
      <div class="p-1">
        <b-table-simple small class="mb-0">
          <b-tr v-for="log in logs" :key="log.id">
            <b-td style="width: 20%">
              <small>
                {{ log.created_at | moment("DD.MM.YYYY HH:mm:ss") }} -
                <strong>{{ log.created_by }}</strong>
              </small>
            </b-td>
            <b-td class="small">
              <router-link
                :to="{ name: 'order.show', params: { orderId: log.order_id } }"
              >
                #{{ log.order_id }}
              </router-link>
            </b-td>
            <b-td>
              <small
                :class="{
                  'text-warning': log.type == 1,
                  'text-danger': log.type == 2,
                }"
              >
                {{ log.message }}
              </small>
            </b-td>
          </b-tr>
        </b-table-simple>
        <div></div>
      </div>
    </b-card>

    <template v-if="pagination">
      <small>Kokku: {{ pagination.total }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="pageChange"
      ></b-pagination>
    </template>

  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      showErrorMessage: false,
      adminUser: null,
      currentPage: 1,
      pagination: null,
      logs: [],
    };
  },
  mounted() {
    this.fetchAdminUser().then(() => {
      this.fetchAdminUserLogs();
    });
  },
  computed: {},
  methods: {
    fetchAdminUser() {
      this.isLoading = true;
      return RestService.getAdminUser(this.$route.params.adminUserId).then(
        (response) => {
          this.adminUser = response.data.data;
          this.isLoading = false;
        }
      ).catch(() => {
        this.isLoading = false;
        this.showErrorMessage = true;
      });
    },
    fetchAdminUserLogs(page = 1) {
      this.isLoading = true;
      RestService.getAdminUserLogs(this.adminUser.id, {
        page: page
      }).then(
        (response) => {
          this.logs = response.data.data;
          this.pagination = response.data.meta.pagination;
          this.isLoading = false;
        }
      ).catch(() => {
        this.isLoading = false;
      });
    },
    pageChange(page) {
      this.fetchAdminUserLogs(page);
    },
  },
};
</script>
