import { Contacts, ContactEdit, ContactNew } from "../components";

export default [
  {
    path: "/contacts",
    component: Contacts,
    name: "contacts",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/contacts/new",
    component: ContactNew,
    name: "contacts.new",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
  {
    path: "/contacts/:contactId",
    component: ContactEdit,
    name: "contacts.edit",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
