import Vue from "vue";

export const Contacts = Vue.component("contacts", require("./Contacts.vue").default);
export const ContactEdit = Vue.component(
  "contacts-edit",
  require("./ContactEdit.vue").default
);
export const ContactNew = Vue.component(
  "contacts-new",
  require("./ContactNew.vue").default
);
